import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { sendContactMail } from '../features/contact/contactSlice'
import { useDispatch, useSelector } from 'react-redux'

const initialState = {
  name: '',
  email: '',
  contactM: '',
  contactN: '',
  contactE: '',
}

function Contact() {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((store) => store.contact)
  const [values, setValues] = useState(initialState)

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { name, email, contactN, contactE, contactM } = values
    if (name !== '' || email !== '') {
      toast.error(
        'Un bot est suspecté, merci de laisser les champs cachés vides'
      )
      return
    }
    if (!contactN || !contactE) {
      toast.error(`Merci de renseigner les champs nom et adresse mail.`)
      return
    }
    dispatch(
      sendContactMail({
        name: contactN,
        email: contactE,
        message: contactM,
      })
    )
    // reset all cells
    cleanValues()
  }

  const cleanValues = () => {
    setValues({
      ...values,
      name: '',
      email: '',
      contactN: '',
      contactE: '',
      contactM: '',
    })
  }

  return (
    <div className='container my-24 mx-auto md:px-6 min-h[1080px]'>
      <section className='mb-32 text-center mx-8'>
        <div className='mx-auto max-w-[700px] md:px-3'>
          <h2 className='mb-6 text-3xl font-bold font-jost uppercase text-coral'>
            Contactez-nous
          </h2>
          <p className='mb-12'>
            Pour découvrir comment nous pouvons ensemble redéfinir vos besoins
            numérique de manière éthique, accessible et inclusive.{' '}
          </p>
          <form onSubmit={onSubmit}>
            {/* H o n e y p o t */}
            <label className='cheatbot' htmlFor='name'></label>
            <input
              className='cheatbot'
              autoComplete='off'
              type='text'
              id='name'
              name='name'
              placeholder='Your name here'
              onChange={handleChange}
              value={values.name}
              maxLength='100'
            />
            <label className='cheatbot' htmlFor='email'></label>
            <input
              className='cheatbot'
              autoComplete='off'
              type='email'
              id='email'
              name='email'
              placeholder='Your e-mail here'
              onChange={handleChange}
              value={values.email}
              maxLength='100'
            ></input>
            {/* H o n e y p o t E N D*/}
            <div className=' mb-6' data-te-input-wrapper-init>
              <input
                type='text'
                name='contactN'
                className='block min-h-[auto] w-full rounded bg-blue-charte/10 py-[0.32rem] px-3 leading-[1.6] outline-1 focus:outline focus:outline-blue-charte'
                id='contactN'
                placeholder='Nom'
                onChange={handleChange}
                value={values.contactN}
                maxLength='100'
              />
            </div>
            <div className=' mb-6' data-te-input-wrapper-init>
              <input
                type='email'
                name='contactE'
                className='block min-h-[auto] w-full rounded bg-blue-charte/10 py-[0.32rem] px-3 leading-[1.6] outline-1 focus:outline focus:outline-blue-charte'
                id='contactE'
                placeholder='Adresse e-mail'
                onChange={handleChange}
                value={values.contactE}
                maxLength='100'
              />
            </div>
            <div className=' mb-6' data-te-input-wrapper-init>
              <textarea
                className='block min-h-[auto] w-full rounded bg-blue-charte/10 py-[0.32rem] px-3 leading-[1.6] outline-1 focus:outline focus:outline-blue-charte'
                id='contactM'
                name='contactM'
                type='text'
                rows='4'
                placeholder='Votre message'
                onChange={handleChange}
                maxLength='500'
                value={values.contactM}
              ></textarea>
            </div>

            <button
              type='submit'
              disabled={isLoading}
              data-te-ripple-init
              data-te-ripple-color='light'
              className='mb-6 inline-block w-full rounded bg-coral px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-coral/80 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-coral/80 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-coral/70 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] '
            >
              {isLoading ? `En cours d'envoi ...` : 'Envoyer'}
            </button>
          </form>
        </div>
      </section>
    </div>
  )
}

export default Contact
