import React from 'react'
import { Link } from 'react-scroll'
import { GrLinkedin } from 'react-icons/gr'
import { Link as LinkReactRouter, useLocation } from 'react-router-dom'
import Logo from './icons/Logo'
import moment from 'moment'

function Footer() {
  const location = useLocation()
  return (
    <div>
      <div className='w-full h-[240px] bg-coral bg-contain flex md:bg-bgFooter bg-no-repeat'>
        <div id='logo' className='mt-6  mx-auto cursor-pointer'>
          <Link
            activeclass='active'
            to='Hero'
            spy={true}
            smooth='true'
            offset={0}
            duration={500}
            href='#'
          >
            <Logo className='max-w-[187px] fill-white absolute -translate-x-1/2 left-1/2 ' />
          </Link>
        </div>
        <div id='nav' className='absolute hidden sm:block right-10 md:right-20'>
          {location.pathname === '/' && (
            <>
              <ul className='cursor-pointer text-white font-jost font-black uppercase text-[20px] select-none text-right pt-12'>
                <li className='px-[5px] hover:bg-white hover:text-coral transition'>
                  <Link
                    activeclass='active'
                    to='Agence'
                    spy={true}
                    smooth='true'
                    offset={-50}
                    duration={500}
                    href='#'
                  >
                    L'agence
                  </Link>
                </li>

                <li className='px-[5px] hover:bg-white hover:text-coral transition'>
                  <Link
                    activeclass='active'
                    to='offres'
                    spy={true}
                    smooth='true'
                    offset={-90}
                    duration={500}
                    href='#'
                  >
                    Nos offres
                  </Link>
                </li>
                <li className='px-[5px] hover:bg-white hover:text-coral transition'>
                  <Link
                    activeclass='active'
                    to='valeurs'
                    spy={true}
                    smooth='true'
                    offset={-90}
                    duration={500}
                    href='#'
                  >
                    Nos valeurs
                  </Link>
                </li>
                <li className='px-[5px] hover:bg-white hover:text-coral transition'>
                  <Link
                    activeclass='active'
                    to='contact'
                    spy={true}
                    smooth='true'
                    offset={-130}
                    duration={500}
                    href='#'
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <a
                href='https://www.linkedin.com/company/rose-lagon'
                target='_blank'
                rel='noreferrer'
              >
                <p className='flex text-white text-[16px] float-right pr-1 pt-4'>
                  <span>Suivez nous sur &nbsp;</span>{' '}
                  <GrLinkedin className='text-[20px]' />
                </p>
              </a>
            </>
          )}
        </div>
      </div>
      <div>
        <div className='p-4 text-center text-[14px] mx-auto uppercase'>
          <LinkReactRouter to='/mentions' className='hover:underline'>
            mentions légales
          </LinkReactRouter>
          &nbsp;--&nbsp;
          <LinkReactRouter to='/politique' className='hover:underline'>
            politique de confidentialité
          </LinkReactRouter>
        </div>
        {/* <a
          href={`https://bff.ecoindex.fr/redirect/?url=${domain}`}
          target='_blank'
        >
          <img
            src={`https://bff.ecoindex.fr/badge/?theme=dark&url=${domain}`}
            alt='Ecoindex Badge'
          />
        </a> */}
        <p className='text-center text-[14px] mx-auto relative lg:absolute lg:right-0 -mt-2 lg:-mt-10 lg:pr-20 mb-5'>
          © {moment().year()} Copyright: Rose Lagon
        </p>
      </div>
    </div>
  )
}

export default Footer
