import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useQuery } from '../utils/queryParams'
import { verifyUser } from '../features/contact/contactSlice'
import { useLocation } from 'react-router-dom'

const BetaLagoonConfirm = () => {
  const query = useQuery(useLocation().search)
  const dispatch = useDispatch()
  const { isLoading, error, isSuccess } = useSelector((store) => store.contact)

  useEffect(() => {
    const email = query.get('email')
    const verificationToken = query.get('token')
    if (!email || !verificationToken) {
      toast.error('Impossible de vérifier votre email')
      return
    }
    dispatch(
      verifyUser({
        email: email,
        verificationToken: verificationToken,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='pt-32'>
      {isLoading ? (
        <></>
      ) : isSuccess ? (
        <SuccessLayout />
      ) : (
        error !== '' && <ErrorLayout text={error} />
      )}
    </div>
  )
}

const ErrorLayout = ({ text }) => {
  return (
    <section>
      <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
        <div className='mx-auto max-w-screen-sm text-center'>
          <h1 className='mb-4 text-6xl tracking-tight font-extrabold lg:text-8xl text-primary-600 dark:text-primary-500'>
            Erreur !
          </h1>
          <p className='mb-4 text-3xl tracking-tight font-bold text-gray md:text-4xl'>
            {text}
          </p>
        </div>
      </div>
    </section>
  )
}

const SuccessLayout = () => {
  return (
    <section>
      <div className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
        <div className='mx-auto max-w-screen-sm text-center'>
          <h1 className='mb-4 text-6xl tracking-tight font-extrabold lg:text-8xl text-primary-600 dark:text-primary-500'>
            Félicitations !
          </h1>
          <p className='mb-4 text-3xl tracking-tight font-bold text-gray md:text-4xl dark:text-white'>
            Votre inscription à la beta de LAGOON est maintenant terminée.
          </p>
          <p className='mb-4 text-lg font-light text-gray-500'>
            Nous reviendrons vers vous dès que la plateforme sera prête à vous
            accueillir.
          </p>
        </div>
      </div>
    </section>
  )
}

export default BetaLagoonConfirm
