import React from 'react'
import { motion } from 'framer-motion'

const isMobile = window.innerWidth < 640

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.5,
        },
      },
    }
  : {}

const animRight = !isMobile
  ? {
      offscreen: {
        opacity: 0,
        x: 300,
      },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.2,
          mass: 0.4,
          damping: 8,

          staggerChildren: 0.1,
          delay: 0.2,
        },
      },
    }
  : {}

const animScale = !isMobile
  ? {
      offscreen: {
        scale: 0,
      },
      onscreen: {
        scale: 1,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 2,
        },
      },
    }
  : {}

const Offres = () => {
  return (
    <div className='pt-20 max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto overflow-hidden mb-16'>
      <motion.div
        initial='offscreen'
        whileInView='onscreen'
        viewport={{ once: true, amount: 0.4 }}
        className='max-w-[1200px]'
      >
        <motion.p
          variants={animTop}
          className='text-[35px] sm:text-[45px] font-jost font-black text-coral uppercase leading-8 sm:leading-10 mx-auto'
        >
          Concrétiser vos idées de manière éthique, durable et inclusive
        </motion.p>
        <motion.div
          variants={animRight}
          className='pl-0 lg:pl-8 mt-12 max-w-[1200px]'
        >
          <p className='font-normal'>
            Le numérique de demain est un numérique inclusif, accessible et qui
            a le moins d'impact possible sur notre environnement.
          </p>
          <p className='font-bold mt-6 upper'>Découvrez nos offres :</p>
        </motion.div>
      </motion.div>
      <motion.div className='container sm:overflow-hidden snap-mandatory snap-x flex flex-row overflow-x-scroll  sm:grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-6'>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.2 }}
          variants={animScale}
          className='mb-10 min-w-[300px]  snap-center'
        >
          <div className='overflow-hidden'>
            <div className='bg-spriteOffres  w-[500px]  h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-120px_-330px] sm:bg-[-10px_-330px]  xl:bg-[-50px_-330px]'></div>
          </div>
          <p className='italic font-bold text-center mt-5'>
            Digitalisation de parcours :
          </p>
          <p className='mt-4 px-0 sm:px-6'>
            Transformez vos formations en présentiel en expériences numériques
            interactives et engageantes. Nous créons également des parcours de
            formation complets pour répondre à tous vos besoins.
          </p>
        </motion.div>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.2 }}
          variants={animScale}
          className='mb-10 min-w-[300px] snap-center'
        >
          <div className='overflow-hidden'>
            <div className='bg-spriteOffres  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-70px_-650px] sm:bg-[-10px_-650px] xl:bg-[-40px_-650px]'></div>
          </div>
          <p className='italic font-bold text-center mt-5'>
            E-learning sur mesure :
          </p>
          <p className='mt-4 px-0 sm:px-6'>
            Nos modules de formation personnalisés répondent parfaitement à vos
            besoins. Nous y intégrons des éléments interactifs tels que des
            quiz, des vidéos et des jeux pour rendre votre formation plus
            engageante.
          </p>
        </motion.div>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.2 }}
          variants={animScale}
          className='mb-10 min-w-[300px] snap-center'
        >
          <div className='overflow-hidden'>
            <div className='bg-spriteOffres  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-530px_-750px] sm:bg-[-530px_-650px]'></div>
          </div>
          <p className='italic font-bold text-center mt-5'>
            Vidéo interactive :
          </p>
          <p className='mt-4 px-0 sm:px-6'>
            Nous transformons vos contenus en vidéos interactives pour vous
            aider à former et communiquer plus efficacement !
            <br /> Notre solution "fait maison" rendra votre public plus actif
            et impliqué.
          </p>
        </motion.div>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.2 }}
          variants={animScale}
          className='mb-10 xl:mb-20 min-w-[300px] snap-center'
        >
          <div className='overflow-hidden'>
            <div className='bg-spriteOffres  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-580px_-40px] sm:bg-[-530px_-10px] xl:bg-[-580px_-10px]'></div>
          </div>
          <p className='italic font-bold text-center mt-5'>
            Data & visualisation :
          </p>
          <p className='mt-4 px-0 sm:px-6'>
            Nous sommes là pour vous aider à collecter, analyser et utiliser les
            données pour améliorer vos prises de décision. Vous êtes aux
            commandes de vos données pour les exploiter au mieux et atteindre
            vos objectifs.
          </p>
        </motion.div>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.2 }}
          variants={animScale}
          className='mb-10 xl:mb-20 min-w-[300px] snap-center'
        >
          <div className='overflow-hidden'>
            <div className='bg-spriteOffres  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-100px_-50px] sm:bg-[-10px_-10px] xl:bg-[-35px_-10px]'></div>
          </div>
          <p className='italic font-bold text-center mt-5'>
            Communication responsable :
          </p>
          <p className='mt-4 px-0 sm:px-6'>
            Nous vous accompagnons dans la mise en place d'une communication
            responsable visant à réduire l'impact environnemental de votre
            activité. Nous promouvons des valeurs positives et durables tout au
            long du processus de création.
          </p>
        </motion.div>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.2 }}
          variants={animScale}
          className='mb-10 xl:mb-20 min-w-[300px] snap-center'
        >
          <div className='overflow-hidden'>
            <div className='bg-spriteOffres  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-530px_-380px] sm:bg-[-530px_-330px]'></div>
          </div>
          <p className='italic font-bold text-center mt-5'>
            Motion design & podcast :
          </p>
          <p className='mt-4 px-0 sm:px-6'>
            Nous créons des animations graphiques 2D & 3D captivantes pour vous
            aider à communiquer efficacement avec votre public : formation,
            publicité, communication, et plus encore.
          </p>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Offres
