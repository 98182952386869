import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { sendContactMailThunk } from './contactThunk'

const initialState = {
  isLoading: false,
  error: '',
  isSuccess: false,
}

export const sendContactMail = createAsyncThunk(
  'roselagon/contacts',
  async (contact, thunkAPI) => {
    return sendContactMailThunk('/roselagon/contacts', contact, thunkAPI)
  }
)

export const sendBetaContactMail = createAsyncThunk(
  'roselagon/betacontacts',
  async (contact, thunkAPI) => {
    return sendContactMailThunk('/roselagon/betacontacts', contact, thunkAPI)
  }
)

export const verifyUser = createAsyncThunk(
  'roselagon/betacontacts/verifyUser',
  async (emailProps, thunkAPI) => {
    return sendContactMailThunk(
      '/roselagon/betacontacts/verify-email',
      emailProps,
      thunkAPI
    )
  }
)

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(sendContactMail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendContactMail.fulfilled, (state) => {
        state.isLoading = false
        toast.success(
          `Votre contact a été transmis à l'équipe Roselagon. Merci de votre confiance`
        )
      })
      .addCase(sendContactMail.rejected, (state) => {
        state.isLoading = false
        toast.error(`Une erreur est survenue`)
      })
      .addCase(sendBetaContactMail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendBetaContactMail.fulfilled, (state) => {
        state.isLoading = false
        toast.success(
          `Confirmez votre adresse mail depuis votre boite de messagerie`,
          { autoClose: 20000 }
        )
      })
      .addCase(sendBetaContactMail.rejected, (state) => {
        state.isLoading = false
        toast.error(`Une erreur est survenue`)
      })
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.error = ''
      })
      .addCase(verifyUser.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(verifyUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isSuccess = false
        state.error = payload
      })
  },
})

export default contactSlice.reducer
