import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'
import { Outlet } from 'react-router-dom'
import { motion } from 'framer-motion'
import Footer from './Footer'

import LogoHeader from './icons/LogoHeader'

const windowHeight = window.outerHeight

const animRight = {
  offscreen: {
    opacity: 0,
    x: 300,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 0.2,
      mass: 0.4,
      damping: 8,
      staggerChildren: 0.1,
    },
  },
}

const animNav = {
  off: {
    opacity: 0,
    transition: {
      type: 'ease',
      duration: 0.3,
    },
  },
  on: {
    opacity: 1,
    transition: {
      type: 'ease',
      duration: 0.3,
    },
  },
}

const Navbar = () => {
  const [hidden, sethidden] = useState(false)
  const [scrolled, SetScolled] = useState(false)
  useEffect(() => {
    if (window) {
      scroll.scrollToTop()
      const onScroll = () =>
        window.requestAnimationFrame(() => {
          const currentScrollYPosition = window.scrollY
          if (currentScrollYPosition > windowHeight - 200) {
            SetScolled(true)
          } else {
            SetScolled(false)
          }
        })
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <div className=' '>
      <motion.div
        className='w-full z-[99999] bg-white py-4 fixed  drop-shadow-lg'
        animate={scrolled ? 'on' : 'off'}
        viewport={{ once: false, amount: 0 }}
        variants={animNav}
      >
        <div className='flex justify-between items-center mx-4 sm:mx-10 md:mx-20 max-w-[1360px] sc:mx-auto'>
          <div
            id='logo'
            className=' cursor-pointer mr-10 h-10 sm:h-16 w-[150px] sm:w-[230px]'
          >
            <Link
              activeClass='active'
              to='Hero'
              spy={true}
              smooth='true'
              offset={0}
              duration={500}
              href='#'
            >
              <LogoHeader className='object-scale-down max-w-[230px] max-h-[40px] sm:max-h-[64px]' />
            </Link>
          </div>
          <div id='nav' className=''>
            <ul className='cursor-pointer text-coral font-jost text-center leading-5 font-black uppercase text-[20px] select-none flex-1 flex justify-center items-center gap-6 xl:gap-8 max-lg:hidden'>
              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link
                  activeClass='active'
                  to='Agence'
                  spy={true}
                  smooth='true'
                  offset={-50}
                  duration={500}
                  href='#'
                >
                  L'agence
                </Link>
              </li>

              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link
                  activeClass='active'
                  to='offres'
                  spy={true}
                  smooth='true'
                  offset={-90}
                  duration={500}
                  href='#'
                >
                  Nos offres
                </Link>
              </li>
              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link
                  activeClass='active'
                  to='valeurs'
                  spy={true}
                  smooth='true'
                  offset={-90}
                  duration={500}
                  href='#'
                >
                  Nos valeurs
                </Link>
              </li>
              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link
                  activeClass='active'
                  to='lagoon'
                  spy={true}
                  smooth='true'
                  offset={-90}
                  duration={500}
                  href='#'
                >
                  Lagoon
                </Link>
              </li>
              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link
                  activeClass='active'
                  to='equipe'
                  spy={true}
                  smooth='true'
                  offset={-90}
                  duration={500}
                  href='#'
                >
                  l'équipe
                </Link>
              </li>
              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link
                  activeClass='active'
                  to='contact'
                  spy={true}
                  smooth='true'
                  offset={-130}
                  duration={500}
                  href='#'
                >
                  Contact
                </Link>
              </li>
            </ul>

            {/* burger menu */}

            <motion.svg
              whileTap={{ scale: 0.9 }}
              className='hidden max-lg:block cursor-pointer outline-transparent hover:stroke-blue-charte  stroke-coral'
              onClick={() => sethidden(!hidden)}
              width='35px'
              height='35px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g hidden={hidden}>
                <line
                  x1='1'
                  y1='4'
                  x2='23'
                  y2='4'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <line
                  x1='1'
                  y1='12'
                  x2='23'
                  y2='12'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <line
                  x1='1'
                  y1='20'
                  x2='23'
                  y2='20'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
              </g>
              <g hidden={!hidden}>
                <path d='M1 1L23 23' strokeWidth='2' strokeLinecap='round' />
                <path d='M1 23L23 1' strokeWidth='2' strokeLinecap='round' />
              </g>
            </motion.svg>
          </div>
        </div>

        {/* nav mobile */}

        <div id='nav_mobile' className='pt-5 pb-10 ' hidden={!hidden}>
          <motion.ul
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0 }}
            variants={animRight}
            className='cursor-pointer text-coral font-jost font-black uppercase text-[30px] select-none text-right px-10'
          >
            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link
                activeClass='active'
                to='Agence'
                spy={true}
                smooth='true'
                offset={-50}
                duration={500}
                onClick={() => sethidden(!hidden)}
                href='#'
              >
                L'agence
              </Link>
            </li>

            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link
                activeClass='active'
                to='offres'
                spy={true}
                smooth='true'
                offset={-50}
                duration={500}
                onClick={() => sethidden(!hidden)}
                href='#'
              >
                Nos offres
              </Link>
            </li>
            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link
                activeClass='active'
                to='valeurs'
                spy={true}
                smooth='true'
                offset={-50}
                duration={500}
                onClick={() => sethidden(!hidden)}
                href='#'
              >
                Nos valeurs
              </Link>
            </li>
            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link
                activeClass='active'
                to='lagoon'
                spy={true}
                smooth='true'
                offset={-50}
                duration={500}
                onClick={() => sethidden(!hidden)}
                href='#'
              >
                lagoon
              </Link>
            </li>
            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link
                activeClass='active'
                to='equipe'
                spy={true}
                smooth='true'
                offset={-110}
                duration={500}
                onClick={() => sethidden(!hidden)}
                href='#'
              >
                l'équipe
              </Link>
            </li>
            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link
                activeClass='active'
                to='contact'
                spy={true}
                smooth='true'
                offset={-110}
                duration={500}
                onClick={() => sethidden(!hidden)}
                href='#'
              >
                Contact
              </Link>
            </li>
          </motion.ul>
        </div>
      </motion.div>

      <Outlet />
      <section className=''>
        <Footer />
      </section>
    </div>
  )
}

export default Navbar
