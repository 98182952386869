const CheckBoxContact = ({ id, onChangeCallback, value, text }) => {
  return (
    <div className='flex items-center text-left'>
      <input
        id={id}
        name={id}
        onChange={onChangeCallback}
        checked={value}
        type='checkbox'
        className='relative peer shrink-0 appearance-none w-4 h-4 border-2 border-coral rounded-sm bg-white mt-1 checked:border-0 checked:bg-coral'
      />
      <label htmlFor={id} className='ms-2 text-sm font-medium text-gray'>
        {text}
      </label>
      <svg
        className='absolute w-4 h-4 mt-1 hidden text-white peer-checked:block pointer-events-none'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <polyline points='20 6 9 17 4 12'></polyline>
      </svg>
    </div>
  )
}
export default CheckBoxContact
