import React from "react";
import { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const isMobile = window.innerWidth < 640;

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.8,
        },
      },
    }
  : {};

const animRight = !isMobile
  ? {
      offscreen: {
        opacity: 0,
        x: 300,
      },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          duration: 0.2,
          mass: 0.4,
          damping: 8,
          staggerChildren: 0.1,
          delay: 0.2,
        },
      },
    }
  : {};

function Ecoconception() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "start end"],
  });
  const scale = useTransform(scrollYProgress, [1.5, 0], ["50%", "100%"]);

  return (
    <motion.div
      ref={ref}
      className="relative md:min-h-[1000px] bg-bgValeurs overflow-hidden"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
    >
      <div className="h-8 w-full bg-gradient-to-b from-[#679298] opacity-30"></div>
      <div className="pt-12 max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto">
        <motion.p
          variants={animTop}
          className="text-[35px] sm:text-[45px] font-jost font-black text-white uppercase leading-8 sm:leading-10 "
        >
          éco-conception : <br /> LES VALEURS D'UN NUMÉRIQUE RESPONSABLE
        </motion.p>
      </div>
      <div className="max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto pt-16 pb-20 md:pb-0 ">
        <div className="">
          <motion.div className="relative md:flex md:space-x-[5px] mt-6 md:mt-0 mx-6 sm:mx-0 md:mx-0 ">
            <div className="ml-0 lg:ml-8 text-white lg:max-w-[50%]">
              <motion.p variants={animRight} className="font-bold  mt-8 ">
                Le numérique de demain est un numérique inclusif, accessible et
                qui a le moins d'impact possible sur notre environnement.
              </motion.p>
              <motion.p variants={animRight} className=" mt-12 ">
                Pour répondre aux éxigences d’un Numérique responsable et
                garantir une expérience utilisateur unique, engageante et
                évolutive nos équipes ont éco-développés différents outils.
                Vidéo interactive, onepage scénarisé, gamification de modules de
                formations, nous prêtons une attention très particulière à
                l’expérience utilisateur.
              </motion.p>
              <motion.p variants={animRight} className=" mt-12 ">
                Nous avons également éco-développé une plateforme de diffusion
                et de formation avec analyse de data,<b> LAGOON™</b>, pours
                s’assurer de concrétiser vos idées de manière éthique, durable
                et inclusive.
              </motion.p>
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div
        className="lg:absolute relative bg-imgEcoConception lg:w-[1083px] w-full lg:h-[724px] h-[70vw] lg:left-1/2 lg:top-44 lg:bg-cover bg-contain bg-no-repeat lg:ml-0 ml-[10%] lg:mt-0 md:mt-10 mt-0 md:block hidden"
        style={{ scale: scale }}
      ></motion.div>
    </motion.div>
  );
}

export default Ecoconception;
