import React from 'react'

const LogoHeader = () => {
  return (
    <svg
      id='Calque_2'
      data-name='Calque 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 279 79.12'
    >
      <defs>
        {/* <style>
      .cls-3 {
        stroke-linejoin: round;
      }

      .cls-3, .cls-3 {
        stroke-linecap: round;
      }

      .cls-3, .cls-3, .cls-3 {
        fill: none;
        stroke: #20868f;
      }

      .cls-4 {
        fill: #ef7d77;
        stroke-width: 0px;
      }

      .cls-3, .cls-3 {
        stroke-miterlimit: 10;
      }
    </style> */}
      </defs>
      <g id='Calque_1-2' data-name='Calque 1'>
        <g>
          <g>
            <path
              className='cls-4'
              d='m94.44,49.14c-.63-9.04-.39-18.13.69-27.12,0-.08.03-.14.05-.21-.02-.25.09-.51.36-.68,1.79-1.12,4.18-.94,5.87.3,3.35,2.46,3.3,7.08,1.98,10.62-1.27,3.4-3.62,6.29-6.66,8.26.05.05.1.11.14.19,2.64,5.01,5.37,9.96,8.21,14.86.5.87-.84,1.65-1.34.79-2.78-4.8-5.47-9.66-8.05-14.57.08,5.12.43,10.23,1.06,15.31.12.99-1.44.98-1.56,0-.32-2.57-.57-5.16-.75-7.74Zm1.23-8.77s.02-.02.03-.03c-.01,0-.02-.02-.03-.03,0,.02,0,.04,0,.06h0Zm.12-1.29c3.36-2.11,5.87-5.56,6.71-9.47.44-2.04.29-4.34-1.05-6.03-1.14-1.44-3.12-2.11-4.78-1.29-.29,2.49-.53,4.98-.69,7.47-.21,3.13-.3,6.27-.3,9.41.04-.03.08-.06.12-.09Z'
            />
            <path
              className='cls-4'
              d='m114.98,56.29c-4.8-5.36-5.17-13.03-4.24-19.82.15-1.08.35-2.15.6-3.21.64-2.64,1.63-5.23,3.11-7.52.73-1.13,1.57-2.19,2.54-3.13.95-.92,2.15-1.92,3.3-2.59,3.07-1.77,7.05.87,8.99,3.22,2.41,2.92,3.45,6.74,3.95,10.43.92,6.76-.28,13.77-3.41,19.83-1.35,2.6-3.24,5.47-6.25,6.27-3.26.86-6.5-1.14-8.6-3.49Zm-2.92-17.74c-.57,6.07.09,12.92,4.74,17.38,2.08,2,5.14,3.4,7.83,1.69,2.36-1.5,3.75-4.43,4.81-6.91,2.4-5.59,3.16-11.94,2.1-17.93-.53-2.99-1.42-6.14-3.41-8.5-.89-1.05-1.97-1.94-3.22-2.52-.79-.37-1.72-.81-2.61-.73-.84.08-1.53.65-2.2,1.11-.93.64-1.82,1.34-2.59,2.17-3.54,3.82-4.97,9.18-5.44,14.24h0Z'
            />
            <path
              className='cls-4'
              d='m146.2,58.46c-1.75.77-3.55.46-4.97-.81-1.45-1.3-2.02-3.25-2.51-5.07-.26-.97,1.24-1.38,1.5-.41.52,1.91,1.2,4.51,3.39,5.11,2.04.56,4.09-1.49,4.96-3.12,2.09-3.89-.55-7.88-2.41-11.26-1.11-2.02-2.23-4.04-3.33-6.06-.98-1.8-1.95-3.65-2.46-5.64-.95-3.74.12-8.06,3.26-10.48,1.43-1.1,3.39-1.69,5.07-.8,1.41.75,2.37,2.45,1.81,4.02-.33.94-1.84.53-1.5-.41.3-.84-.15-1.65-.87-2.13-.82-.54-1.84-.42-2.69,0-1.89.92-3.12,2.97-3.63,4.94-1.06,4.14,1.4,7.99,3.32,11.47,1.12,2.03,2.24,4.05,3.35,6.08.97,1.77,1.95,3.62,2.29,5.63.6,3.58-1.18,7.45-4.58,8.95h0Z'
            />
            <path
              className='cls-4'
              d='m156.22,58.1c-.06-2.72-.1-5.43-.13-8.15-.1-9.61-.01-19.23.25-28.84.01-.39.35-.82.78-.78,1.43.14,2.86.12,4.29-.05.99-.12.98,1.44,0,1.56-1.18.14-2.35.17-3.53.1-.06,2.44-.12,4.88-.16,7.31-.07,4.08-.1,8.16-.1,12.24,1.54,0,3-.16,4.49-.53.41-.1.84.13.96.54.11.4-.14.86-.54.96-1.6.4-3.26.59-4.91.58,0,4.73.06,9.47.15,14.2,2.58-.23,5.12-.73,7.6-1.5.96-.3,1.37,1.21.41,1.5-2.85.88-5.8,1.41-8.78,1.61-.43.03-.77-.38-.78-.78Z'
            />
            <path
              className='cls-4'
              d='m183.48,57.39c-.44-.04-.75-.33-.78-.78-.16-2.61-.28-5.22-.34-7.83-.23-9.12.14-18.25,1.08-27.33.1-.99,1.66-1,1.56,0-.27,2.6-.49,5.2-.67,7.81-.59,8.86-.62,17.76-.11,26.63,1.95.12,3.88-.1,5.77-.66.96-.29,1.37,1.22.41,1.5-2.25.66-4.59.89-6.92.65h0Z'
            />
            <path
              className='cls-4'
              d='m206.43,52.28c-.51-2.26-.78-4.56-1.04-6.86-.06-.5-.12-1-.18-1.5-1.93.38-5.17.33-6.1,0-.58,4.57-1,9.07-1.44,13.65-.09.99-1.65,1-1.56,0,.26-2.74.52-5.47.81-8.2,1.01-9.54,2.39-19.1,5.36-28.26.21-.66,1.38-.82,1.5,0,.44,2.97.86,5.94,1.26,8.91.7,5.2,1.33,10.41,1.92,15.63.51,4.48,1.15,9.11,3.65,12.98.55.85-.8,1.63-1.34.79-1.4-2.17-2.28-4.61-2.84-7.12Zm-1.4-9.91c-.18-1.58-.36-3.17-.56-4.75-.52-4.24-1.1-8.48-1.7-12.72-.41,1.46-.79,2.93-1.14,4.41-1.01,4.34-1.74,8.74-2.33,13.15,1.64.26,4,.27,5.73-.09Z'
            />
            <path
              className='cls-4'
              d='m220.26,56.8c-1.32-1.76-2.06-4.04-2.69-6.12-1.39-4.57-1.87-9.42-1.58-14.18.24-4.15.88-8.67,3.3-12.18,1.16-1.69,2.78-3.23,4.76-3.87,1.91-.62,4.15-.08,5.21,1.71.51.87-.84,1.65-1.34.79-1.17-1.98-3.79-1.13-5.24,0-1.54,1.18-2.64,2.86-3.38,4.64-1.44,3.47-1.79,7.41-1.83,11.13-.04,4.18.54,8.36,1.85,12.33.57,1.72,1.2,3.61,2.34,5.04.47.59,1.09,1.16,1.86,1.3.87.15,1.67-.3,2.23-.94.66-.75.96-1.74,1.19-2.69.24-1.02.42-2.06.57-3.1.27-1.93.38-3.89.37-5.84-.9.15-1.79.41-2.62.79-.91.41-1.7-.93-.79-1.34,1.34-.61,2.72-.98,4.18-1.11.43-.04.77.39.78.78.07,2.92-.16,5.85-.68,8.73-.43,2.35-1.11,5.11-3.59,6.07-1.95.75-3.75-.38-4.89-1.91Z'
            />
            <path
              className='cls-4'
              d='m247.86,59.77c-3.26.86-6.5-1.14-8.6-3.49-4.8-5.36-5.17-13.03-4.24-19.82.15-1.08.35-2.15.6-3.21.64-2.64,1.63-5.23,3.11-7.52.73-1.13,1.57-2.19,2.54-3.13.95-.92,2.15-1.92,3.3-2.59,3.07-1.77,7.05.87,8.99,3.22,2.41,2.92,3.45,6.74,3.95,10.43.92,6.76-.28,13.77-3.41,19.83-1.35,2.6-3.24,5.47-6.25,6.27h0Zm-11.52-21.23c-.57,6.07.09,12.92,4.74,17.38,2.08,2,5.14,3.4,7.83,1.69,2.36-1.5,3.75-4.43,4.81-6.91,2.4-5.59,3.16-11.94,2.1-17.93-.53-2.99-1.42-6.14-3.41-8.5-.89-1.05-1.97-1.94-3.22-2.52-.79-.37-1.72-.81-2.61-.73-.84.08-1.53.65-2.2,1.11-.93.64-1.82,1.34-2.59,2.17-3.54,3.82-4.97,9.18-5.44,14.24h0Z'
            />
            <path
              className='cls-4'
              d='m273.8,50.9c-2.64-8.58-6.38-16.77-9.27-25.26v3.11s.07,28.68.07,28.68c0,1-1.55,1-1.56,0l-.02-8.17-.07-28.68c0-.89,1.29-1.04,1.53-.21.84,2.91,1.82,5.78,2.87,8.63,3.01,8.17,6.56,16.19,8.83,24.59l.11-2.9,1.15-31.02c.04-1,1.59-1,1.56,0l-.33,8.79-1.15,31.02c-.03.84-1.36,1.1-1.53.21-.56-2.97-1.31-5.9-2.2-8.78Z'
            />
          </g>
          <g>
            <path
              className='cls-3'
              d='m21.09,44.14c-.37,1.53-.74,3.21-1.12,5.06-2.16,10.57-11.24,16.35-15.86,15.35'
            />
            <path
              className='cls-3'
              d='m34.57,26.49c-6.39,2.53-9.54,3.6-12.57,14.16'
            />
            <path
              className='cls-3'
              d='m30.54,43.24c-1.89,2.34-2.8,5.77-1.88,10.72,1.38,7.42,4.6,12.7-3.59,20.17'
            />
            <path
              className='cls-3'
              d='m47.16,38.48c-3.73-.27-9.67-.16-13.89,2.41'
            />
            <path
              className='cls-3'
              d='m36.02,48.72c-2.34,1.89-5.77,2.8-10.72,1.88-7.42-1.38-12.7-4.6-20.17,3.59'
            />
            <path
              className='cls-3'
              d='m40.78,32.11c.27,3.73.16,9.67-2.41,13.89'
            />
            <path
              className='cls-3'
              d='m36.31,28.36c-.69,3.53-1.16,8.93-12.61,7.73-11.44-1.2-17.19,5.78-18.15,8.14'
            />
            <path
              className='cls-3'
              d='m50.82,42.88c-3.53.69-8.93,1.16-7.73,12.61,1.2,11.44-5.78,17.19-8.14,18.15'
            />
            <circle className='cls-3' cx='2.31' cy='64.52' r='1.8' />
            <path
              className='cls-3'
              d='m34.99,58.03c-1.53.37-3.21.74-5.06,1.12-10.57,2.16-16.35,11.24-15.35,15.86'
            />
            <path
              className='cls-3'
              d='m52.63,44.55c-2.53,6.39-3.6,9.54-14.16,12.57'
            />
            <circle className='cls-3' cx='14.6' cy='76.82' r='1.8' />
            <circle className='cls-3' cx='31.81' cy='41.95' r='1.8' />
            <circle className='cls-3' cx='37.32' cy='47.46' r='1.8' />
            <circle className='cls-3' cx='23.79' cy='75.41' r='1.8' />
            <circle className='cls-3' cx='3.94' cy='55.55' r='1.8' />
            <circle className='cls-3' cx='4.72' cy='45.94' r='1.8' />
            <circle className='cls-3' cx='33.32' cy='74.54' r='1.8' />
            <circle className='cls-3' cx='21.53' cy='42.39' r='1.8' />
            <circle className='cls-3' cx='36.74' cy='57.59' r='1.8' />
            <g>
              <path
                className='cls-3'
                d='m34.7,10.37c-4.18-1.22-2.03-5.75.65-5.95.82-.06,2.36.55,2.64,3.05'
              />
              <circle className='cls-3' cx='32.76' cy='16.07' r='2.66' />
              <path
                className='cls-3'
                d='m42.95,17.71c1.68.73,2.98,2.17,3.4,3.48.57,1.78.09,2.8-.97,5.14-1.24,2.73,1.52,5.36-.02,7.56'
              />
              <path
                className='cls-3'
                d='m46.1.5c.41.11,2.56.85.91,4.81-1.65,3.96,1.39,5.74,2.58,6.85,1.2,1.11,3.6,3.13,3.85,5.76.28,3.02,1.57,4.67,2.65,5.25'
              />
              <path
                className='cls-3'
                d='m51.15,25.11s-.48-4.11-1.9-6.95c-1.43-2.84-5.23-4.86-5.92-6.23-1.02-2.01-1.25-5.66-.36-7.44.85-1.71-1.15-3.45-2.56-2.62-1.36.8-1.91,2.09-2.31,3.75-.89,3.77,3.02,6.43-2.77,9.53'
              />
              <path
                className='cls-3'
                d='m62.65,8.44c-1.02-3.78-2.68-5.12-5.23-6.26-1.82-.82-4.63-.56-5.99.98-1.02,1.16-1.64,3.87.48,5.71,2.11,1.84,5.63,5.06,6.49,8.35'
              />
              <path
                className='cls-3'
                d='m43.32,11.93s-1.86,1.21-3.12,3.45c-1.11,1.98-1.25,3.29.06,5.01'
              />
              <circle className='cls-3' cx='51.47' cy='27.75' r='2.66' />
              <circle className='cls-3' cx='60.72' cy='18.5' r='2.66' />
              <circle className='cls-3' cx='39.87' cy='23.19' r='2.66' />
              <path
                className='cls-3'
                d='m63.25,3.21s2.67.81,3.82,3.6c.31.74.52,2.34-.38,3.68-.96,1.43-2.82,3.3-4.76,2.45-2.82-1.24-1.98-3.3-4.19-5.47-1.26-1.23-3.05-1.88-3.05-1.88'
              />
              <path
                className='cls-3'
                d='m68.88,44.56c1.22,4.18,5.75,2.03,5.95-.65.06-.82-.55-2.36-3.05-2.64'
              />
              <circle className='cls-3' cx='63.18' cy='46.5' r='2.66' />
              <path
                className='cls-3'
                d='m61.55,36.31c-.73-1.68-2.17-2.98-3.48-3.4-1.78-.57-2.8-.09-5.14.97-2.73,1.24-5.36-1.52-7.56.02'
              />
              <path
                className='cls-3'
                d='m78.76,33.15c-.11-.41-.85-2.56-4.81-.91-3.96,1.65-5.74-1.39-6.85-2.58-1.11-1.2-3.13-3.6-5.76-3.85-3.02-.28-4.67-1.57-5.25-2.65'
              />
              <path
                className='cls-3'
                d='m54.14,28.1s4.11.48,6.95,1.9c2.84,1.43,4.86,5.23,6.23,5.92,2.01,1.02,5.66,1.25,7.44.36s3.45,1.15,2.62,2.56c-.8,1.36-2.09,1.91-3.75,2.31-3.77.89-6.43-3.02-9.53,2.77'
              />
              <path
                className='cls-3'
                d='m70.82,16.61c3.78,1.02,5.12,2.68,6.26,5.23.82,1.82.56,4.63-.98,5.99-1.16,1.02-3.87,1.64-5.71-.48s-5.06-5.63-8.35-6.49'
              />
              <path
                className='cls-3'
                d='m67.32,35.93s-1.21,1.86-3.45,3.12c-1.98,1.11-3.29,1.25-5.01-.06'
              />
              <circle className='cls-3' cx='56.06' cy='39.38' r='2.66' />
              <path
                className='cls-3'
                d='m76.05,16.01s-.81-2.67-3.6-3.82c-.74-.31-2.34-.52-3.68.38-1.43.96-3.3,2.82-2.45,4.76,1.24,2.82,3.3,1.98,5.47,4.19,1.23,1.26,1.88,3.05,1.88,3.05'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoHeader
