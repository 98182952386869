import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { sendBetaContactMail } from '../features/contact/contactSlice'
import CheckBoxContact from '../Components/CheckBoxContact'

const BetaLagoon = () => {
  const initialState = {
    name: '',
    lastName: '',
    email: '',
    contactN: '',
    contactL: '',
    contactE: '',
    contactC1: false,
    contactC2: false,
    contactC3: false,
    contactC4: false,
    contactC: false,
  }

  const dispatch = useDispatch()
  const { isLoading } = useSelector((store) => store.contact)
  const [values, setValues] = useState(initialState)

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setValues({ ...values, [name]: value })
  }

  const handleCheckBox = (e) => {
    const name = e.target.name
    const checked = e.target.checked
    setValues({ ...values, [name]: checked })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const {
      name,
      lastName,
      email,
      contactN,
      contactL,
      contactE,
      contactC1,
      contactC2,
      contactC3,
      contactC4,
    } = values
    if (name !== '' || lastName !== '' || email !== '') {
      toast.error(
        'Un bot est suspecté, merci de laisser les champs cachés vides.'
      )
      return
    }
    if (!contactN || !contactE || !contactL) {
      toast.error(`Merci de renseigner les champs nom, prénom et adresse mail.`)
      return
    }
    if (!contactC1 && !contactC2 && !contactC3 && !contactC4) {
      toast.error(`Merci de renseigner au moins une catégorie de contenu.`)
      return
    }
    dispatch(
      sendBetaContactMail({
        name: contactN,
        lastName: contactL,
        email: contactE,
        preventionContent: contactC1,
        nrContent: contactC2,
        climateContent: contactC3,
        rseContent: contactC4,
      })
    )
    // reset all cells
    cleanValues()
  }

  const cleanValues = () => {
    setValues({
      ...values,
      name: '',
      lastName: '',
      email: '',
      contactN: '',
      contactL: '',
      contactE: '',
      contactC1: false,
      contactC2: false,
      contactC3: false,
      contactC4: false,
    })
  }

  return (
    <div className='max-w-[950px] px-4 mx-auto pt-32 mb-32'>
      <div className='container my-24 mx-auto md:px-6 min-h[1080px]'>
        <section className='mb-32 text-center mx-8'>
          <div className='mx-auto max-w-[700px] md:px-3'>
            <h2 className='text-3xl font-bold font-jost uppercase text-coral'>
              Programme d'inscription beta
            </h2>
            <h2 className='mb-6 text-3xl font-bold font-jost uppercase text-coral'>
              à LAGOON
            </h2>
            <p className='mb-6'>
              Inscrivez-vous sur nos listes d'attente pour vous permettre de
              découvrir gratuitement en avant première nos contenus de
              formation.
            </p>
            <p className='mb-6 italic text-sm'>
              Les données recoltées par ce formulaire serviront uniquement à
              vous envoyer un lien d'accès beta quand la plateforme LAGOON sera
              prête à vous accueillir.
            </p>
            <a
              href='https://roselagon.com/lagoon_web.pdf'
              alt='PDF Lagoon'
              target='_blank'
              rel='noreferrer'
              type='submit'
              data-te-ripple-init
              data-te-ripple-color='light'
              className='mb-6 text-center w-56
                inline-block  rounded bg-coral px-6 pt-2.5 pb-2 text-xs
                font-medium leading-normal text-white
                shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150
                ease-in-out hover:bg-coral/80
                hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                focus:bg-coral/80
                focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                focus:outline-none focus:ring-0 active:bg-coral/70
                active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                '
            >
              Voir la présentation LAGOON™
            </a>
            <form onSubmit={onSubmit}>
              {/* H o n e y p o t */}
              <label className='cheatbot' htmlFor='name'></label>
              <input
                className='cheatbot'
                autoComplete='off'
                type='text'
                id='name'
                name='name'
                placeholder='Your name here'
                onChange={handleChange}
                value={values.name}
                maxLength='100'
              />
              <label className='cheatbot' htmlFor='lastName'></label>
              <input
                className='cheatbot'
                autoComplete='off'
                type='text'
                id='lastName'
                name='lastName'
                placeholder='Your last name here'
                onChange={handleChange}
                value={values.name}
                maxLength='100'
              />
              <label className='cheatbot' htmlFor='email'></label>
              <input
                className='cheatbot'
                autoComplete='off'
                type='email'
                id='email'
                name='email'
                placeholder='Your e-mail here'
                onChange={handleChange}
                value={values.email}
                maxLength='100'
              ></input>
              {/* H o n e y p o t E N D*/}
              <div className=' mb-6' data-te-input-wrapper-init>
                <input
                  type='text'
                  name='contactN'
                  className='block min-h-[auto] w-full rounded bg-blue-charte/10 py-[0.32rem] px-3 leading-[1.6] outline-1 focus:outline focus:outline-blue-charte'
                  id='contactN'
                  placeholder='Nom'
                  onChange={handleChange}
                  value={values.contactN}
                  maxLength='100'
                />
              </div>
              <div className=' mb-6' data-te-input-wrapper-init>
                <input
                  type='text'
                  name='contactL'
                  className='block min-h-[auto] w-full rounded bg-blue-charte/10 py-[0.32rem] px-3 leading-[1.6] outline-1 focus:outline focus:outline-blue-charte'
                  id='contactL'
                  placeholder='Prénom'
                  onChange={handleChange}
                  value={values.contactL}
                  maxLength='100'
                />
              </div>
              <div className=' mb-6' data-te-input-wrapper-init>
                <input
                  type='email'
                  name='contactE'
                  className='block min-h-[auto] w-full rounded bg-blue-charte/10 py-[0.32rem] px-3 leading-[1.6] outline-1 focus:outline focus:outline-blue-charte'
                  id='contactE'
                  placeholder='Adresse e-mail'
                  onChange={handleChange}
                  value={values.contactE}
                  maxLength='100'
                />
              </div>
              <p className='mb-6 italic'>
                Choisissez au moins une catégorie de contenu où vous souhaitez
                être inscrit.
              </p>
              <div className='mb-6 grid grid-cols-1 lg:grid-cols-2 gap-3'>
                <CheckBoxContact
                  id='contactC1'
                  onChangeCallback={handleCheckBox}
                  value={values.contactC1}
                  text='Contenus liés à la prévention'
                />
                <CheckBoxContact
                  id='contactC2'
                  onChangeCallback={handleCheckBox}
                  value={values.contactC2}
                  text='Contenus liés au numérique responsable'
                />
                <CheckBoxContact
                  id='contactC3'
                  onChangeCallback={handleCheckBox}
                  value={values.contactC3}
                  text='Contenus liés à la transition écolologique'
                />
                <CheckBoxContact
                  id='contactC4'
                  onChangeCallback={handleCheckBox}
                  value={values.contactC4}
                  text='Contenus liés au RSE'
                />
              </div>

              <button
                type='submit'
                disabled={isLoading}
                data-te-ripple-init
                data-te-ripple-color='light'
                className='mb-6 inline-block w-full rounded bg-coral px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-coral/80 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-coral/80 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-coral/70 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] '
              >
                {isLoading
                  ? `En cours d'envoi ...`
                  : "S'incrire en liste d'attente"}
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  )
}

export default BetaLagoon
