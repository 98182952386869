import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { animateScroll as scroll } from 'react-scroll'
import Footer from './Footer'

import LogoHeader from './icons/LogoHeader'

const animRight = {
  offscreen: {
    opacity: 0,
    x: 300,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.2,
      duration: 0.2,
      mass: 0.4,
      damping: 8,
      staggerChildren: 0.1,
    },
  },
}

const NavbarAlt = ({ children }) => {
  const [hidden, sethidden] = useState(false)
  useEffect(() => {
    scroll.scrollToTop()
  }, [children])

  return (
    <div className=' '>
      <div className='w-full z-[99999] bg-white py-4  fixed px-12 drop-shadow-lg'>
        <div className='flex justify-between items-center max-w-[1520px] 2xl:mx-auto'>
          <div
            id='logo'
            className=' cursor-pointer mr-10 h-10 sm:h-16 w-[150px] sm:w-[230px]'
          >
            <Link
              activeclass='active'
              to='/#Hero'
              spy='true'
              smooth='true'
              offset={0}
              duration={500}
              href='#'
            >
              <LogoHeader className='object-scale-down max-w-[230px] max-h-[40px] sm:max-h-[64px]' />
            </Link>
          </div>
          <div id='nav' className=''>
            <ul className='cursor-pointer text-coral font-jost font-black uppercase text-[20px] select-none flex-1 flex justify-center items-center gap-8 max-lg:hidden'>
              <li className='px-[5px] hover:bg-coral hover:text-white transition'>
                <Link className='active' to='/'>
                  Retour
                </Link>
              </li>
            </ul>

            {/* burger menu */}

            <motion.svg
              whileTap={{ scale: 0.9 }}
              className='hidden max-lg:block cursor-pointer  hover:stroke-blue-charte  stroke-coral'
              onClick={() => sethidden(!hidden)}
              width='35px'
              height='35px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g hidden={hidden}>
                <line
                  x1='1'
                  y1='4'
                  x2='23'
                  y2='4'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <line
                  x1='1'
                  y1='12'
                  x2='23'
                  y2='12'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <line
                  x1='1'
                  y1='20'
                  x2='23'
                  y2='20'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
              </g>
              <g hidden={!hidden}>
                <path d='M1 1L23 23' strokeWidth='2' strokeLinecap='round' />
                <path d='M1 23L23 1' strokeWidth='2' strokeLinecap='round' />
              </g>
            </motion.svg>
          </div>
        </div>

        {/* nav mobile */}

        <div id='nav_mobile' className='pt-5 pb-10 ' hidden={!hidden}>
          <motion.ul
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0 }}
            variants={animRight}
            className='cursor-pointer text-coral font-jost font-black uppercase text-[30px] select-none text-right px-10'
          >
            <li className='pr-2 my-[20px] hover:bg-coral hover:text-white transition'>
              <Link className='active' to='/'>
                Retour
              </Link>
            </li>
          </motion.ul>
        </div>
      </div>
      {children}
      <section className=''>
        <Footer />
      </section>
    </div>
  )
}

export default NavbarAlt
