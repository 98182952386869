import React from 'react'
import { motion } from 'framer-motion'

// import logosResponsable from '../Assets/logos_responsable.jpg'
import logosResponsable from '../Assets/logos_responsable_1.jpg'
import logosFresque from '../Assets/logos_fresque.jpg'

const isMobile = window.innerWidth < 640

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.8,
        },
      },
    }
  : {}

const animScale = !isMobile
  ? {
      offscreen: {
        scale: 1.4,
      },
      onscreen: {
        scale: 1,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 2,
        },
      },
    }
  : {}

const animRight = !isMobile
  ? {
      offscreen: {
        opacity: 0,
        x: 300,
      },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.2,
          mass: 0.4,
          damping: 8,
          staggerChildren: 0.1,
          delay: 0.2,
        },
      },
    }
  : {}

function Reinventer() {
  return (
    <motion.div
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: true, amount: 0.4 }}
      className='overflow-hidden'
    >
      <div className='h-8 w-full bg-gradient-to-b from-[#679298] opacity-70 '></div>
      <motion.p
        variants={animTop}
        className='text-[35px] sm:text-[45px] font-jost font-black text-coral uppercase leading-8 sm:leading-10 pt-12 max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto'
      >
        RÉINVENTER
        <br />
        LE PAYSAGE DIGITAL
      </motion.p>
      <div className='max-w-[1360px]  sc:mx-auto'>
        <div className='lg:flex flex-row-reverse '>
          <div id='left' className='lg:max-w-[750px]  lg:pr-20 2xl:pr-0'>
            <motion.div
              variants={animRight}
              className='px-4 sm:px-10 md:px-20 lg:px-6 mt-12'
            >
              <p className='font-bold italic leading-6  '>
                Pour être un acteur du changement, il est impératif de
                s'impliquer activement dans la transition écologique et sociale
                :&nbsp;
                <span className='font-normal'>
                  c'est pourquoi Rose Lagon s'engage dans le processus de
                  labelisation Numérique Responsable. Nous sommes aussi
                  signataire du Référentiel Marketing Digital Responsable.
                  {/* labelisation Numérique Responsable au près de l’INR* & de
                  l’agence LUCIE. */}
                </span>
              </p>
              {/* <p className='font-ralway font-normal italic mt-2 pb-4 md:pb-6 text-[14px]'>
                *(Institut Numérique Responsable)
              </p> */}
              <div className='max-w-[600px] pt-4'>
                <img
                  alt=''
                  // width='822px'
                  width='315px'
                  height='132px'
                  src={logosResponsable}
                  className='object-cover'
                />
              </div>
              <p className='italic mt-4 pb-4 leading-6'>
                Nous réalisons des “serious game” (Fresque du Numérique et
                Atelier 2Tonnes) pour sensibiliser et former vos
                collabora-teur-trices aux enjeux environnementaux du numérique
                ou sur les leviers d’actions à mettre en place dans leurs
                entreprises pour accélérer la transition.
              </p>
              <div className='max-w-[500px]'>
                <img
                  alt=''
                  width='591px'
                  height='127px'
                  src={logosFresque}
                  className='object-cover'
                />
              </div>
            </motion.div>
          </div>
          <motion.div
            variants={animScale}
            id='right'
            className='hidden sm:block -z-50 bg-section2 bg-center md:bg-right bg-contain bg-no-repeat md:w-[700px] min-h-[700px]'
          ></motion.div>
        </div>
      </div>

      <div className='h-8 w-full bg-gradient-to-t from-[#679298] opacity-70 '></div>
    </motion.div>
  )
}

export default Reinventer
