import React from 'react'
import { Link as LinkReactRouter } from 'react-router-dom'

const Politique = () => {
  return (
    <div className='max-w-[950px] px-4 mx-auto pt-32 mb-32'>
      <p className=' text-[25px] text-center font-bold text-blue-charte'>
        Politique de confidentialité
      </p>
      <p className='text-center'>Rose Lagon </p>
      <br />
      <p className='font-bold text-blue-charte'>ARTICLE 1 : PRÉAMBULE </p>
      <p>
        La présente politique de confidentialité a pour but d’informer les
        utilisateurs du site :
      </p>
      <ul className='list-[circle] pl-6'>
        <li>
          Sur la manière dont sont collectées leurs données personnelles. Sont
          considérées comme des données personnelles, toute information
          permettant d’identifier un utilisateur. A ce titre, il peut s’agir :
          de ses noms et prénoms, de son âge, de son adresse postale ou email,
          de sa localisation ou encore de son adresse IP (liste non-exhaustive)
          ;
        </li>
        <li>Sur les droits dont ils disposent concernant ces données ; </li>
        <li>
          Sur la personne responsable du traitement des données à caractère
          personnel collectées et traitées ;
        </li>
        <li>Sur les destinataires de ces données personnelles ; </li>
        <li>Sur la politique du site en matière de cookies. </li>
      </ul>
      <br />
      <p>
        Cette politique complète les mentions légales et les Conditions
        Générales d’Utilisation consultables par les utilisateurs à l’adresse
        suivante :
      </p>
      <p>
        <LinkReactRouter to='mentions' className='hover:underline'>
          https://roselagon.com/mentions-legales.html
        </LinkReactRouter>
      </p>
      <br />
      <p className='font-bold text-blue-charte'>
        ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES
        DONNÉES PERSONNELLES
      </p>
      <p>
        Conformément à l’article 5 du Règlement européen 2016/679, les données à
        caractère personnel sont :
      </p>
      <ul className='list-[circle] pl-6'>
        <li>
          Traitées de manière licite, loyale et transparente au regard de la
          personne concernée ;
        </li>
        <li>
          Collectées pour des finalités déterminées (cf. Article 3.1 des
          présentes), explicites et légitimes, et ne pas être traitées
          ultérieurement d&apos;une manière incompatible avec ces finalités ;
        </li>
        <li>
          Adéquates, pertinentes et limitées à ce qui est nécessaire au regard
          des finalités pour lesquelles elles sont traitées ;
        </li>
        <li>
          Exactes et, si nécessaire, tenues à jour. Toutes les mesures
          raisonnables doivent être prises pour que les données à caractère
          personnel qui sont inexactes, eu égard aux finalités pour lesquelles
          elles sont traitées, soient effacées ou rectifiées sans tarder ;
        </li>
        <li>
          Conservées sous une forme permettant l'identification des personnes
          concernées pendant une durée n&apos;excédant pas celle nécessaire au
          regard des finalités pour lesquelles elles sont traitées ;
        </li>
        <li>
          Traitées de façon à garantir une sécurité appropriée des données
          collectées, y compris la protection contre le traitement non autorisé
          ou illicite et contre la perte, la destruction ou les dégâts
          d&apos;origine accidentelle, à l&apos;aide de mesures techniques ou
          organisationnelles appropriées.
        </li>
      </ul>
      <br />
      <p>
        Le traitement n&apos;est licite que si, et dans la mesure où, au moins
        une des conditions suivantes est remplie :
      </p>
      <ul className='list-[circle] pl-6'>
        <li>
          La personne concernée a consenti au traitement de ses données à
          caractère personnel pour une ou plusieurs finalités spécifiques ;
        </li>
        <li>
          Le traitement est nécessaire à l&apos;exécution d&apos;un contrat
          auquel la personne concernée est partie ou à l&apos;exécution de
          mesures précontractuelles prises à la demande de celle-ci ;
        </li>
        <li>
          Le traitement est nécessaire au respect d&apos;une obligation légale à
          laquelle le responsable du traitement est soumis ;
        </li>
        <li>
          Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la
          personne concernée ou d&apos;une autre personne physique ;
        </li>
        <li>
          Le traitement est nécessaire à l&apos;exécution d&apos;une mission
          d&apos;intérêt public ou relevant de l&apos;exercice de
          l&apos;autorité publique dont est investi le responsable du traitement
          ;
        </li>
        <li>
          Le traitement est nécessaire aux fins des intérêts légitimes
          poursuivis par le responsable du traitement ou par un tiers, à moins
          que ne prévalent les intérêts ou les libertés et droits fondamentaux
          de la personne concernée qui exigent une protection des données à
          caractère personnel, notamment lorsque la personne concernée est un
          enfant.
        </li>
      </ul>
      <br />
      <p className='font-bold text-blue-charte'>
        ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE
        CADRE DE LA NAVIGATION SUR LE SITE
      </p>
      <p className='font-bold'>Article 3.1 : Données collectées </p>
      <p>
        Les données personnelles collectées dans le cadre de notre activité sont
        les suivantes : <br />
        Nom, adresse e-mail
      </p>
      <br />
      <p>
        La collecte et le traitement de ces données répond à la (aux)
        finalité(s) suivante(s) : <br />
        Formulaire de contact pour pouvoir suivre les sollicitations de l’équipe
        RoseLagon
        <br />
        exemple : gestion de contrat, gestion de l’espace client, suivi de la
        qualité des services, envoi de newsletter, etc.
      </p>
      <br />
      <p className='font-bold'>Article 3.2 : Mode de collecte des données </p>
      <p>
        Lorsque vous utilisez notre site, sont automatiquement collectées les
        données suivantes : <br />
        Aucune
      </p>
      <br />
      <p>
        D’autres données personnelles sont collectées lorsque vous effectuez les
        opérations suivantes sur la plateforme : <br />
        Lors de l’utilisation du formulaire de contact, un mail à l’adresse
        contact@roselagon.com est envoyé ainsi qu’un enregistrement sur une base
        de données.
      </p>
      <p>
        Elles sont conservées par le responsable du traitement dans des
        conditions raisonnables de sécurité, pour une durée de : 3 ans.
      </p>
      <br />
      <p>
        La société est susceptible de conserver certaines données à caractère
        personnel au-delà des délais annoncés ci-dessus afin de remplir ses
        obligations légales ou réglementaires.
      </p>
      <br />
      <p className='font-bold'>Article 3.3 : Hébergement des données </p>
      <p>
        Le site roselagon.com est hébergé par : <br />
        Infomaniak Network SA
        <br />
        Rue Eugène-Marziano 25, 1227 Genève - Suisse <br />
        https://contact.infomaniak.com
        <br />
        CHE-185-477.109
      </p>
      <br />
      <p className='font-bold text-blue-charte'>
        ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA
        PROTECTION DES DONNÉES
      </p>
      <p className='font-bold'>
        Article 4.1 : Le responsable du traitement des données
      </p>
      <p>
        Les données à caractère personnelles sont collectées par Rose Lagon, SAS
        au capital de cinq milles euros, dont le numéro d’immatriculation est le
        R.C.S Toulouse B 979 528 742
      </p>
      <p>
        Le responsable du traitement des données à caractère personnel peut être
        contacté de la manière suivante : <br />
        Par courrier à l’adresse : 1 rue Dautezac 31300 Toulouse <br />
        Par téléphone, au 09.77.75.72.16 <br />
        Par mail : pierre.maccini@roselagon.com.
      </p>
      <br />
      <p className='font-bold'>
        Article 4.2 : Le délégué à la protection des données
      </p>
      <p>
        Le délégué à la protection des données de l’entreprise ou du responsable
        est : <br />
        M. Maccini Pierre, pierre.maccini@roselagon.com
      </p>
      <p>
        Si vous estimez, après nous avoir contactés, que vos droits
        “Informatique et Libertés”, ne sont pas respectés, vous pouvez adresser
        une information à la CNIL.
      </p>
      <br />
      <p className='font-bold text-blue-charte'>
        ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE
        TRAITEMENT DES DONNÉES
      </p>
      <p>
        Tout utilisateur concerné par le traitement de ses données personnelles
        peut se prévaloir des droits suivants, en application du règlement
        européen 2016/679 et de la Loi Informatique et Liberté (Loi 78-17 du 6
        janvier 1978) :
      </p>
      <ul className='list-[circle] pl-6'>
        <li>
          Droit d’accès, de rectification et droit à l’effacement des données
          (posés respectivement aux articles 15, 16 et 17 du RGPD) ;
        </li>

        <li>Droit à la portabilité des données (article 20 du RGPD) ; </li>

        <li>
          Droit à la limitation (article 18 du RGPD) et à l’opposition du
          traitement des données (article 21 du RGPD) ;
        </li>

        <li>
          Droit de ne pas faire l’objet d’une décision fondée exclusivement sur
          un procédé automatisé ;
        </li>

        <li>Droit de déterminer le sort des données après la mort ; </li>

        <li>
          Droit de saisir l’autorité de contrôle compétente (article 77 du
          RGPD).
        </li>
      </ul>
      <br />
      <p>
        Pour exercer vos droits, veuillez adresser votre courrier à Rose Lagon,
        1 rue Dautezac 31300 Toulouse ou par mail à pierre.maccini@roselagon.com
      </p>
      <br />
      <p>
        Afin que le responsable du traitement des données puisse faire droit à
        sa demande, l’utilisateur peut être tenu de lui communiquer certaines
        informations telles que : ses noms et prénoms, son adresse e-mail ainsi
        que son numéro de compte, d’espace personnel ou d’abonné.
      </p>
      <p>Consultez le site cnil.fr pour plus d’informations sur vos droits. </p>
      <br />
      <p className='font-bold text-blue-charte'>
        ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
        CONFIDENTIALITÉ
      </p>
      <p>
        L’éditeur du site Rose Lagon se réserve le droit de pouvoir modifier la
        présente Politique à tout moment afin d’assurer aux utilisateurs du site
        sa conformité avec le droit en vigueur.
      </p>
      <br />
      <p>
        Les éventuelles modifications ne sauraient avoir d’incidence sur les
        achats antérieurement effectués sur le site, lesquels restent soumis à
        la Politique en vigueur au moment de l’achat et telle qu’acceptée par
        l’utilisateur lors de la validation de l’achat.
      </p>
      <br />
      <p>
        L’utilisateur est invité à prendre connaissance de cette Politique à
        chaque fois qu’il utilise nos services, sans qu’il soit nécessaire de
        l’en prévenir formellement.
      </p>
      <br />
      <p>
        La présente politique, éditée le 1/10/2023 a été mise à jour le
        27/10/2023.
      </p>
    </div>
  )
}

export default Politique
