import React from 'react'
import { motion } from 'framer-motion'
import imgDigitalisation from '../Assets/digitalisation.avif'
import imgDigitalisationVertical from '../Assets/digitalisation_vertical.avif'

const isMobile = window.innerWidth < 640

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.5,
        },
      },
    }
  : {}

const animRight = !isMobile
  ? {
      offscreen: {
        opacity: 0,
        x: 300,
      },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.2,
          mass: 0.4,
          damping: 8,

          staggerChildren: 0.1,
          delay: 0.2,
        },
      },
    }
  : {}

// const animScale = !isMobile
//   ? {
//       offscreen: {
//         scale: 0,
//       },
//       onscreen: {
//         scale: 1,
//         transition: {
//           type: "spring",
//           bounce: 0.2,
//           duration: 2,
//         },
//       },
//     }
//   : {};

const Digitalisation = () => {
  return (
    <div className='pt-20 max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto overflow-hidden mb-16'>
      <motion.div
        initial='offscreen'
        whileInView='onscreen'
        viewport={{ once: true, amount: 0.4 }}
        className='max-w-[1200px]'
      >
        <motion.p
          variants={animTop}
          className='text-[35px] sm:text-[45px] font-jost font-black text-coral uppercase leading-8 sm:leading-10 mx-auto'
        >
          DIGITALISATION by ROSE LAGON : <br />
          l’utilisateur avant tout !
        </motion.p>
        <motion.div
          variants={animRight}
          className='pl-0 lg:pl-8 mt-12 max-w-[750px]'
        >
          <p className='font-normal italic'>
            De la définition des obejctifs pédagogique à la diffusion sur notre
            plateforme LAGOON™,{' '}
            <span className='font-bold'>
              notre engagement premier est d’offrir une pédagogie innovante et
              dynamique
            </span>{' '}
            en mettant l'accent sur{' '}
            <span className='font-bold'>
              une expérience de formation centrée sur l'apprenant.
            </span>
          </p>
        </motion.div>
      </motion.div>
      {window.innerWidth < 800 ? (
        <div className='flex mt-10 sm:leading-6 leading-5'>
          <img
            alt=''
            width='139px'
            src={imgDigitalisationVertical}
            className='object-cover relative  '
          />
          <div className='flex flex-col gap-[4vw] '>
            <div className='text-[#FF6781] '>
              <p className=' italic text-3xl font-bold '>Exploration</p>
              <p>
                Identification des objectifs pédagogique et des éléments clés à
                adapter pour le format digital.
              </p>
            </div>
            <div className='text-[#CB758D] '>
              <p className=' italic text-3xl font-bold'>Coopération</p>
              <p>
                Création du parcours de formation, définition des modalités
                pédagogique les plus engageantes
              </p>
            </div>
            <div className='text-[#998398] '>
              <p className=' italic text-3xl font-bold'>Transformation</p>
              <p>
                Conception de l’expérience de formation et des indicateurs de
                suivi pour rendre l’apprenant acteur de son apprentissage.
              </p>
            </div>
            <div className='text-[#6790A3] '>
              <p className=' italic text-3xl font-bold'>Inspiration</p>
              <p>
                Mise en lumière de l’identité visuelle de la formation pour
                optimiser les ancrages mémoriels par des concepts innovants.
              </p>
            </div>
            <div className='text-[#359EAE] '>
              <p className=' italic text-3xl font-bold'>Création</p>
              <p>
                Développement écoconçu de la solution de formation et liaison
                avec la plateforme LAGOON™.
              </p>
            </div>

            <div className='text-[#00ACBA] '>
              <p className=' italic text-3xl font-bold'>Évolution</p>
              <p>
                Analyse innovante des données post-formation et personnalisation
                des parcours apprenants.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='flex mt-16 text-center xl:gap-16 gap-[5vw] w-[84.5%]'>
            <div className='text-[#FF6781] '>
              <p className=' italic text-3xl font-bold'>Exploration</p>
              <p>
                Identification des objectifs pédagogique et des éléments clés à
                adapter pour le format digital.
              </p>
              <div className='flex justify-center'>
                <div className='h-28 w-0 border-l-[10px] border-dotted border-[#FF6781]'></div>
              </div>
            </div>
            <div className='text-[#998398] '>
              <p className=' italic text-3xl font-bold'>Transformation</p>
              <p>
                Conception de l’expérience de formation et des indicateurs de
                suivi pour rendre l’apprenant acteur de son apprentissage.
              </p>
              <div className='flex justify-center'>
                <div className='h-28 w-0 border-l-[10px] border-dotted border-[#998398]'></div>
              </div>
            </div>
            <div className='text-[#359EAE] '>
              <p className=' italic text-3xl font-bold'>Création</p>
              <p>
                Développement écoconçu de la solution de formation et liaison
                avec la plateforme LAGOON™.
              </p>
              <div className='flex justify-center'>
                <div className='h-28 w-0 border-l-[10px] border-dotted border-[#359EAE]'></div>
              </div>
            </div>
          </div>
          {/* <div className="bg-imgDigitalisation max-w-[1364px] h-[309px] bg-contain bg-no-repeat -mt-8 -mb-12"></div> */}
          <img
            alt=''
            width='1330px'
            src={imgDigitalisation}
            className='object-cover relative -z-10 -mt-8 -mb-12 pl-8'
          />
          <div className='flex  pl-[15.5%] text-center xl:gap-16 gap-[5vw] '>
            <div className='text-[#CB758D] '>
              <div className='flex justify-center'>
                <div className='h-28 w-0 border-l-[10px] border-dotted border-[#CB758D]'></div>
              </div>
              <p className=' italic text-3xl font-bold'>Coopération</p>
              <p>
                Création du parcours de formation, définition des modalités
                pédagogique les plus engageantes
              </p>
            </div>
            <div className='text-[#6790A3] '>
              <div className='flex justify-center'>
                <div className='h-28 w-0 border-l-[10px] border-dotted border-[#6790A3]'></div>
              </div>
              <p className=' italic text-3xl font-bold'>Inspiration</p>
              <p>
                Mise en lumière de l’identité visuelle de la formation pour
                optimiser les ancrages mémoriels par des concepts innovants.
              </p>
            </div>
            <div className='text-[#00ACBA] '>
              <div className='flex justify-center'>
                <div className='h-28 w-0 border-l-[10px] border-dotted border-[#00ACBA]'></div>
              </div>
              <p className=' italic text-3xl font-bold'>Évolution</p>
              <p>
                Analyse innovante des données post-formation et personnalisation
                des parcours apprenants.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Digitalisation
