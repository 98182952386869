import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useRef } from 'react'
import { Link } from 'react-scroll'
import Logo from './icons/Logo'

function Hero(props) {
  const isMobile = window.innerWidth < 640
  const ref = useRef(null)
  const { scrollYProgress } = useScroll(
    !isMobile
      ? {
          target: ref,
          offset: ['start start', 'end start'],
        }
      : {}
  )

  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%'])
  const layerThreeY = useTransform(scrollYProgress, [0, 1], ['0%', '90%'])
  const logoY = useTransform(scrollYProgress, [0, 1], ['0%', '150%'])
  const layerTwo = useTransform(scrollYProgress, [0, 1], ['0%', '75%'])
  const layerOne = useTransform(scrollYProgress, [0, 1], ['0%', '40%'])

  return (
    <div ref={ref} className='w-full h-screen overflow-hidden relative '>
      <div className=' '>
        <motion.div
          style={{ y: backgroundY }}
          className='absolute inset-0 z-0 bg-heroBackground bg-top bg-cover  '
        />

        <motion.div
          style={{ y: layerThreeY }}
          className='absolute inset-0 z-0 bg-heroLayerThree bg-top bg-cover'
        />
        <motion.div style={{ y: logoY }} className='absolute inset-0 z-0 '>
          <Logo className='max-w-[250px] fill-white sm:max-w-[calc(15%+2rem)] absolute -translate-x-1/2 left-1/2 mt-24' />
          {/* <div className='absolute inset-0 z-0 bg-logo bg-no-repeat max-w-[250px] sm:max-w-[calc(15%+2rem)] px-10 mx-auto mt-8 sm:mt-24 drop-shadow-[0_10px_10px_rgba(12,28,34,0.4)]'></div> */}
        </motion.div>
        <motion.div
          style={{ y: layerTwo }}
          className='absolute inset-0 z-0 bg-heroLayerTwo bg-top bg-cover'
        />
        <motion.div
          style={{ y: layerOne }}
          className='absolute inset-0 z-20 bg-heroLayerOne bg-top bg-cover '
        />
      </div>
      <Link
        activeclass='active'
        to='Agence'
        spy={true}
        smooth='true'
        offset={-50}
        duration={500}
        href='#'
      >
        <div className='absolute h-32 w-full bg-gradient-to-t from-[#0c1c22] opacity-100 z-50 bottom-0'></div>
        <motion.div
          initial={{ y: -10 }}
          animate={{ y: 20 }}
          transition={{ duration: 1, repeat: Infinity, repeatType: 'mirror' }}
          id='scroll'
          className='left-1/2 absolute bottom-20 z-50 hidden  lg:block'
        >
          <svg
            className='fill-white w-[55px] object-cover -translate-x-1/2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 58.77 29.67'
          >
            <g id='Calque_1-2' data-name='Calque 1'>
              <polygon points='0 0 58.77 0 29.09 29.67 0 0' />
            </g>
          </svg>
        </motion.div>
      </Link>

      <div className='text-white text-center bottom-4 left-1/2 -translate-x-1/2 absolute z-50'>
        <p className=' hidden sm:block'>
          Ce site n'est pas destiné à être imprimé #SauveUnArbre
        </p>
        <p className='font-jost font-black uppercase'>
          #Eco-conception #datalife #accompagner #sensibiliser #former
        </p>
      </div>
    </div>
  )
}

export default Hero
