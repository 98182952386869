import React from "react";
import Hero from "../Components/Hero";
import Agence from "../Components/Agence";
import Reinventer from "../Components/Reinventer";

import Contact from "../Components/Contact";
import Ecoconception from "../Components/Ecoconception";
import Offres from "../Components/Offres";
import Digitalisation from "../Components/Digitalisation";
import Lagoon from "../Components/Lagoon";
import Equipe from "../Components/Equipe";

const Landing = () => {
  return (
    <main>
      <section id="Hero" className="">
        <Hero />
      </section>
      <section id="Agence" className="">
        <Agence />
      </section>
      <section id="offres" className="">
        <Offres />
      </section>
      <section id="valeurs" className="">
        <Ecoconception />
      </section>

      <section className="">
        <Reinventer />
      </section>
      <section className="">
        <Digitalisation />
      </section>
      <section id="lagoon" className="">
        <Lagoon />
      </section>
      <section id="equipe" className="">
        <Equipe />
      </section>

      <section id="contact" className="">
        <Contact />
      </section>
    </main>
  );
};

export default Landing;
