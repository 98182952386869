import React, { useRef } from 'react'

import { motion, useScroll, useTransform } from 'framer-motion'
import imgLaptopPsc1 from '../Assets/laptop_psc1.avif'
import imgMobileLagoon from '../Assets/mobile_lagoon.avif'
import imgMobileFoh from '../Assets/mobile_foh.avif'

import imgLaptopData2 from '../Assets/laptop_data2.avif'

const isMobile = window.innerWidth < 640

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.5,
        },
      },
    }
  : {}

const animRight = !isMobile
  ? {
      offscreen: {
        opacity: 0,
        x: 300,
      },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.2,
          mass: 0.4,
          damping: 8,

          staggerChildren: 0.1,
          delay: 0.2,
        },
      },
    }
  : {}

// const animScale = !isMobile
//   ? {
//       offscreen: {
//         scale: 0,
//       },
//       onscreen: {
//         scale: 1,
//         transition: {
//           type: "spring",
//           bounce: 0.2,
//           duration: 2,
//         },
//       },
//     }
//   : {};

const Lagoon = () => {
  const isMobile = window.innerWidth < 640
  const ref = useRef(null)
  const { scrollYProgress } = useScroll(
    !isMobile
      ? {
          target: ref,
          offset: ['start end', 'end start'],
        }
      : {}
  )

  const layerFour = useTransform(scrollYProgress, [0.8, 0], ['-0%', '50%'])
  const layerThree = useTransform(scrollYProgress, [0.8, 0], ['-0%', '100%'])
  const layerTwo = useTransform(scrollYProgress, [0.8, 0], ['-10%', '60%'])
  const layerOne = useTransform(scrollYProgress, [0.8, 0], ['0%', '30%'])

  return (
    <div className='relative ' ref={ref}>
      <div className='h-8 w-full bg-gradient-to-b from-[#679298] opacity-70 -z-40 relative'></div>

      <div className='pt-20 max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto overflow-hidden mb-16 '>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.4 }}
          className='max-w-[1200px]'
        >
          <motion.p
            variants={animTop}
            className='text-[35px] sm:text-[45px] font-jost font-black text-coral uppercase leading-8 sm:leading-10 max-w-[1000px]'
          >
            LAGOON™ : PLateforme de formation aux enjeux d’avenir !
          </motion.p>
          <motion.div
            variants={animRight}
            className='pl-0 lg:pl-8 mt-12 lg:w-[64%]'
          >
            <p className='font-bold'>
              Un écosystème, pas une plateforme LMS de plus !
            </p>
            <p className='mt-10'>
              Notre solution se démarque par une approche unique, intégrant aux
              formations digitales une analyse approfondie des données.
              Celles-ci fournissent aux services RH et de formation les outils
              nécessaires pour guider efficacement les transformations vers des
              organisations apprenantes.
              <br />
              <br /> Notre équipe, engagée dans l’écoconception des services
              numériques, a pensé LAGOON comme un écosystème harmonieux
              s’adaptant aux besoins des différents utilisateurs (apprenants,
              formateurs, services RH et services formations).Cet écosystème
              communique grâce à notre analyse des DATAs pour assurer une
              collaboration transparente et une communication fluide avec des
              fonctionnalités propres à chacun des utilisateurs qui favorise un
              environnement d'apprentissage dynamique et collaboratif.
            </p>
          </motion.div>
        </motion.div>
        <div className='xl:flex items-end'>
          <img
            alt=''
            width='1197px'
            src={imgLaptopPsc1}
            className='object-cover relative mt-20 sm:block hidden'
          />
          <div className='mt-20 xl:mt-0 lg:-ml-0 xl:-ml-72 2xl:-ml-52   mb-20 z-50'>
            <p className='text-coral font-jost font-black text-2xl mb-4'>
              Vous voulez en sa(voir) plus&nbsp;?
            </p>
            <div className='flex gap-3 max-w-md'>
              <a
                href='https://roselagon.com/lagoon_web.pdf'
                alt='PDF Lagoon'
                target='_blank'
                rel='noreferrer'
                type='submit'
                data-te-ripple-init
                data-te-ripple-color='light'
                className='mb-6 text-center
                inline-block w-full rounded bg-coral px-6 pt-2.5 pb-2 text-xs
                font-medium leading-normal text-white
                shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150
                ease-in-out hover:bg-coral/80
                hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                focus:bg-coral/80
                focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                focus:outline-none focus:ring-0 active:bg-coral/70
                active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]
                '
              >
                Présentation LAGOON™
              </a>

              <a
                href='./beta-lagoon'
                type='submit'
                //disabled={isLoading}
                data-te-ripple-init
                data-te-ripple-color='light'
                className='text-center mb-6 inline-block w-full rounded bg-coral px-6 pt-2.5 pb-2 text-xs font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-coral/80 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-coral/80 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-coral/70 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] '
              >
                Rejoindre le programme Beta
              </a>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        className='absolute lg:left-[55%] left-[65%] bottom-[15%] lg:w-[246px] md:w-[200px] w-[150px] z-10 sm:block hidden'
        style={{ y: layerFour }}
      >
        <img
          alt=''
          width='246px'
          src={imgMobileLagoon}
          className='object-cover '
        />
      </motion.div>
      <motion.div
        className='absolute lg:left-[65%] left-[75%] lg:bottom-[30%] bottom-[27%] lg:w-[246px] w-[200px] md:block hidden'
        style={{ y: layerThree }}
      >
        <img
          alt=''
          width='246px'
          src={imgMobileFoh}
          className='object-cover '
        />
      </motion.div>
      {/* <div className="absolute left-[50%] -top-[5vh]  -z-20">
        <img
          alt=""
          width="900px"
          src={imgLaptopData}
          className="object-cover"
        />
      </div> */}
      <motion.div
        className='absolute left-[50%] -top-[5vh]  -z-20 bg-imgLaptopData w-[900px] h-[1200px] bg-contain bg-no-repeat hidden lg:block'
        style={{ y: layerTwo }}
      ></motion.div>
      <motion.div
        className='absolute left-[61%] xl:left-[58%] top-[15%] -z-30 hidden lg:block'
        style={{ y: layerOne }}
      >
        <img
          alt=''
          width='450px'
          src={imgLaptopData2}
          className='object-cover '
        />
      </motion.div>
    </div>
  )
}

export default Lagoon
