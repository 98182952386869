import React from 'react'

const Mentions = () => {
  return (
    <div className='max-w-[950px] px-4 mx-auto pt-32 mb-32'>
      <p className=' text-[25px] text-center font-bold text-blue-charte'>
        Mentions légales{' '}
      </p>
      <p className='text-center'>En vigueur au 01/10/2023</p>
      <br />
      <p>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
        dite L.C.E.N., il est porté à la connaissance des utilisateurs et
        visiteurs, ci-après l'"Utilisateur", du site https://www.roselagon.com ,
        ci-après le "Site", les présentes mentions légales.
      </p>
      <br />
      <p>
        La connexion et la navigation sur le Site par l’Utilisateur implique
        acceptation intégrale et sans réserve des présentes mentions légales.
      </p>
      <p>
        Ces dernières sont accessibles sur le Site à la rubrique « Mentions
        légales ».
      </p>
      <br />
      <p className='font-bold text-blue-charte'>ARTICLE 1 - L'EDITEUR</p>
      <p>
        L'édition du Site est assurée par Rose Lagon Studio SAS au capital de
        5000 euros, immatriculée au Registre du Commerce et des Sociétés de
        Toulouse sous le numéro 979528742 dont le siège social est situé au 1
        rue Dautezac 31300 Toulouse, <br />
        Numéro de téléphone 09.77.75.72.16, <br />
        Adresse e-mail : contact@roselagon.com.
        <br />
        N° de TVA intracommunautaire : FR47979528742
        <br />
        Le Directeur de la publication est DUBEDOUT François
        <br />
        ci-après l'"Editeur".
      </p>
      <br />
      <p className='font-bold text-blue-charte'>ARTICLE 2 - L'HEBERGEUR</p>
      <p>
        L'hébergeur du Site est la société Infomaniak Network SA , dont le siège
        social est situé au Rue Eugène Marziano 25 1227 Les Acacias (GE).
      </p>
      <br />
      <p className='font-bold text-blue-charte'>ARTICLE 3 - ACCES AU SITE</p>
      <p>
        Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
        majeure, interruption programmée ou non et pouvant découlant d’une
        nécessité de maintenance.
      </p>
      <br />
      <p>
        En cas de modification, interruption ou suspension du Site, l'Editeur ne
        saurait être tenu responsable.
      </p>
      <br />
      <p className='font-bold text-blue-charte'>
        ARTICLE 4 - COLLECTE DES DONNEES
      </p>

      <p>
        Le site est exempté de déclaration à la Commission Nationale
        Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune
        donnée concernant les utilisateurs.
      </p>
      <br />
      <p>
        Toute utilisation, reproduction, diffusion, commercialisation,
        modification de toute ou partie du Site, sans autorisation de l’Editeur
        est prohibée et pourra entraînée des actions et poursuites judiciaires
        telles que notamment prévues par le Code de la propriété intellectuelle
        et le Code civil.
      </p>

      <p>Rédigé sur http://legalplace.fr</p>
    </div>
  )
}

export default Mentions
