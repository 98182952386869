import React from 'react'
import { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

const isMobile = window.innerWidth < 640

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.8,
        },
      },
    }
  : {}

const animBottom = !isMobile
  ? {
      offscreen: {
        y: 300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 2,
          delay: 0.8,
        },
      },
    }
  : {}

const animRight = !isMobile
  ? {
      offscreen: {
        opacity: 0,
        x: 300,
      },
      onscreen: {
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.2,
          mass: 0.4,
          damping: 8,
          staggerChildren: 0.1,
        },
      },
    }
  : {}

function Agence() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'start end'],
  })
  const backgroundX = useTransform(scrollYProgress, [0.2, 1], ['0%', '100%'])

  return (
    <div ref={ref}>
      <div className=' bg-bgAgence bg-[#8acbb1] bg-bottom sm:bg-top bg-auto overflow-hidden xl:h-[750px]  2xl:h-[750px] pb-10'>
        <div className='max-w-[1360px] ml-4 sm:ml-10 md:ml-20 sc:ml-auto mx-auto flex'>
          <motion.div
            id='HeroLeft'
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.4 }}
            className='  max-w-[1400px] h-auto xl:h-[967px]  '
          >
            <motion.div className='lg:max-w-[1400px] mt-20 sm:mt-28'>
              <motion.p
                variants={animTop}
                className='max-w-[1400px] text-[35px] sm:text-[45px] lg:text-[60px] font-jost font-black text-white uppercase leading-8 sm:leading-[40px] lg:leading-[50px]'
              >
                Une nouvelle approche est essentielle
              </motion.p>

              <div className=' ml-0 lg:ml-8 mt-12'>
                <motion.p
                  variants={animRight}
                  className=' font-raleway font-normal text-white mt-4  sm:mx-0 '
                >
                  Studio créatif engagé pour la transition écologique et
                  sociale, Rose Lagon vous aide à concrétiser vos projets
                  digitaux en mettant en avant les valeurs d’un numérique
                  responsable.
                </motion.p>
                <div
                  variants={animRight}
                  className=' font-raleway font-bold text-white flex pt-6 lg:pt-8 leading-[30px] sm:leading-[40px] px-0   xl:px-12'
                >
                  <motion.ul
                    variants={animRight}
                    className='ml-4 sm:ml-10 list-[circle]'
                  >
                    <motion.li variants={animRight}>E-learning</motion.li>
                    <motion.li variants={animRight}>Motion Design</motion.li>
                    <motion.li variants={animRight}>
                      Vidéo interactive
                    </motion.li>
                    <motion.li variants={animRight}>Siteweb & App'</motion.li>
                    <motion.li variants={animRight}>Communication </motion.li>
                  </motion.ul>
                  <motion.ul
                    variants={animRight}
                    className='ml-6 md:ml-14 list-[circle] '
                  >
                    <motion.li variants={animRight}>Serious Games</motion.li>
                    <motion.li variants={animRight}>Podcast</motion.li>
                    <motion.li variants={animRight}>
                      Animation 2D & 3D
                    </motion.li>
                    <motion.li variants={animRight}>UI/UX</motion.li>
                    <motion.li variants={animRight}>
                      Accompagnement éco-conception
                    </motion.li>
                  </motion.ul>
                </div>
                <motion.p
                  variants={animBottom}
                  className='text-[20px] font-raleway font-bold italic text-white mt-6 lg:mt-8 md:mx-0 pb-12'
                >
                  Nous reconnaissons que le monde numérique n’est pas parfait,
                  mais chaque jour nous nous efforçons de le rendre plus
                  vertueux !
                </motion.p>
              </div>
            </motion.div>
          </motion.div>
          <motion.div
            style={{ x: backgroundX }}
            id='HeroRight'
            className='bg-jellyFish bg-cover hidden sm:block  w-[803px] h-[750px]'
          ></motion.div>
        </div>
      </div>
    </div>
  )
}

export default Agence
