import React from 'react'

const Logo = (props) => {
  return (
    <svg
      id='Calque_2'
      data-name='Calque 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 400.78 407.89'
      className={props.className}
    >
      <defs></defs>
      <g id='Calque_3' data-name='Calque 3'>
        <g>
          <path d='m1.61,345.59c-1.35-19.5-.83-39.1,1.5-58.51.02-.16.06-.31.12-.45-.03-.54.19-1.1.78-1.47,3.87-2.42,9.02-2.03,12.67.65,7.22,5.3,7.12,15.26,4.26,22.92-2.74,7.33-7.8,13.57-14.36,17.81.11.11.21.24.3.4,5.69,10.8,11.59,21.49,17.71,32.06,1.09,1.88-1.82,3.56-2.9,1.7-6-10.36-11.79-20.84-17.38-31.43.18,11.04.93,22.07,2.28,33.03.26,2.14-3.1,2.12-3.36,0-.68-5.55-1.23-11.12-1.61-16.71Zm2.65-18.91l.06-.06-.07-.07v.13h0Zm.26-2.79c7.25-4.56,12.67-12,14.47-20.42.94-4.4.63-9.36-2.26-13-2.45-3.1-6.72-4.54-10.32-2.79-.63,5.36-1.14,10.73-1.5,16.12-.45,6.76-.66,13.53-.66,20.3.08-.07.16-.14.26-.2Z' />
          <path d='m45.93,361.01c-10.36-11.57-11.16-28.11-9.14-42.76.32-2.32.75-4.63,1.3-6.91,1.38-5.7,3.52-11.28,6.7-16.22,1.57-2.44,3.39-4.72,5.48-6.75,2.05-2,4.65-4.15,7.13-5.59,6.63-3.83,15.21,1.89,19.39,6.95,5.21,6.3,7.45,14.55,8.53,22.51,1.98,14.58-.59,29.7-7.36,42.77-2.9,5.61-6.98,11.81-13.48,13.53-7.04,1.86-14.02-2.46-18.55-7.53Zm-6.3-38.28c-1.22,13.1.2,27.88,10.22,37.5,4.49,4.32,11.09,7.34,16.88,3.65,5.09-3.24,8.09-9.57,10.38-14.91,5.17-12.07,6.81-25.75,4.53-38.69-1.14-6.45-3.07-13.24-7.37-18.34-1.91-2.27-4.24-4.17-6.94-5.44-1.7-.8-3.71-1.75-5.63-1.57-1.81.17-3.3,1.41-4.74,2.4-2,1.38-3.92,2.89-5.58,4.68-7.64,8.24-10.73,19.8-11.75,30.73h0Z' />
          <path d='m113.27,365.7c-3.78,1.67-7.67.98-10.73-1.76-3.13-2.8-4.35-7.02-5.41-10.93-.57-2.09,2.68-2.98,3.24-.89,1.11,4.12,2.58,9.73,7.32,11.01,4.41,1.2,8.81-3.22,10.7-6.73,4.5-8.39-1.18-17-5.2-24.3-2.4-4.36-4.81-8.71-7.19-13.08-2.12-3.88-4.21-7.87-5.3-12.17-2.06-8.06.25-17.39,7.02-22.61,3.08-2.37,7.31-3.64,10.93-1.72,3.05,1.61,5.11,5.29,3.9,8.68-.72,2.03-3.96,1.15-3.24-.89.64-1.82-.32-3.57-1.87-4.59-1.76-1.17-3.97-.9-5.8,0-4.07,1.99-6.73,6.4-7.82,10.67-2.28,8.94,3.03,17.24,7.16,24.75,2.41,4.38,4.82,8.75,7.22,13.13,2.09,3.82,4.21,7.81,4.94,12.15,1.29,7.72-2.54,16.07-9.89,19.3h0Z' />
          <path d='m134.9,364.92c-.12-5.86-.21-11.72-.27-17.57-.21-20.74-.02-41.48.55-62.21.02-.84.76-1.77,1.68-1.68,3.09.3,6.17.26,9.25-.11,2.15-.26,2.12,3.11,0,3.36-2.54.31-5.07.37-7.61.22-.14,5.26-.26,10.52-.35,15.78-.15,8.8-.21,17.61-.22,26.41,3.32.02,6.47-.34,9.69-1.13.88-.22,1.82.28,2.07,1.17.24.86-.29,1.85-1.17,2.07-3.46.86-7.03,1.27-10.59,1.25.01,10.21.12,20.42.32,30.63,5.57-.49,11.05-1.58,16.39-3.23,2.07-.64,2.95,2.6.89,3.24-6.16,1.9-12.51,3.05-18.94,3.48-.92.06-1.66-.82-1.68-1.68Z' />
          <path d='m193.71,363.38c-.95-.1-1.62-.7-1.68-1.68-.35-5.63-.59-11.26-.73-16.89-.49-19.68.3-39.38,2.32-58.95.22-2.13,3.58-2.15,3.36,0-.58,5.61-1.06,11.22-1.44,16.85-1.28,19.12-1.34,38.31-.23,57.45,4.21.25,8.38-.21,12.45-1.41,2.08-.62,2.96,2.63.89,3.24-4.84,1.43-9.91,1.92-14.94,1.41h0Z' />
          <path d='m243.23,352.37c-1.1-4.87-1.69-9.84-2.25-14.8-.12-1.08-.26-2.16-.38-3.24-4.17.82-11.15.72-13.16-.02-1.25,9.86-2.17,19.56-3.1,29.45-.2,2.14-3.56,2.15-3.36,0,.56-5.9,1.12-11.8,1.74-17.7,2.18-20.59,5.17-41.21,11.57-60.96.46-1.42,2.98-1.76,3.24,0,.95,6.4,1.85,12.81,2.72,19.23,1.51,11.22,2.87,22.46,4.15,33.71,1.1,9.66,2.49,19.66,7.87,27.99,1.18,1.82-1.73,3.51-2.9,1.7-3.02-4.67-4.91-9.96-6.13-15.36Zm-3.03-21.39c-.4-3.42-.79-6.83-1.21-10.25-1.13-9.16-2.37-18.3-3.68-27.43-.89,3.15-1.71,6.32-2.45,9.51-2.18,9.37-3.75,18.85-5.02,28.37,3.53.56,8.63.58,12.36-.2Z' />
          <path d='m273.06,362.12c-2.84-3.8-4.44-8.72-5.81-13.21-3-9.86-4.02-20.32-3.42-30.59.53-8.96,1.91-18.71,7.12-26.27,2.51-3.64,6-6.97,10.28-8.36,4.11-1.33,8.95-.17,11.24,3.69,1.1,1.87-1.8,3.56-2.9,1.7-2.52-4.28-8.17-2.43-11.3-.02-3.32,2.55-5.7,6.17-7.29,10.01-3.1,7.49-3.87,15.98-3.94,24.01-.08,9.01,1.16,18.03,3.99,26.6,1.22,3.71,2.58,7.79,5.05,10.88,1.01,1.26,2.35,2.5,4.01,2.79,1.87.33,3.6-.66,4.82-2.02,1.43-1.61,2.08-3.76,2.56-5.81.52-2.21.9-4.45,1.22-6.69.59-4.17.83-8.39.81-12.61-1.95.32-3.86.89-5.66,1.71-1.95.89-3.66-2-1.7-2.9,2.89-1.32,5.86-2.11,9.02-2.39.92-.08,1.66.83,1.68,1.68.15,6.31-.34,12.62-1.47,18.83-.92,5.06-2.39,11.03-7.74,13.1-4.2,1.62-8.08-.82-10.56-4.13Z' />
          <path d='m314.06,361.01c-10.36-11.57-11.16-28.11-9.14-42.76.32-2.32.75-4.63,1.3-6.91,1.38-5.7,3.52-11.28,6.7-16.22,1.57-2.44,3.39-4.72,5.48-6.75,2.05-2,4.65-4.15,7.13-5.59,6.63-3.83,15.21,1.89,19.39,6.95,5.21,6.3,7.45,14.55,8.53,22.51,1.99,14.58-.59,29.7-7.36,42.77-2.9,5.61-6.98,11.81-13.48,13.53-7.04,1.86-14.02-2.46-18.55-7.53Zm-6.3-38.28c-1.22,13.1.2,27.88,10.22,37.5,4.49,4.32,11.09,7.34,16.88,3.65,5.09-3.24,8.09-9.57,10.38-14.91,5.17-12.07,6.81-25.75,4.53-38.69-1.14-6.45-3.07-13.24-7.37-18.34-1.91-2.27-4.24-4.17-6.94-5.44-1.7-.8-3.71-1.75-5.63-1.57-1.81.17-3.3,1.41-4.74,2.4-2,1.38-3.92,2.89-5.58,4.68-7.64,8.24-10.73,19.8-11.75,30.73h0Z' />
          <path d='m388.58,349.39c-5.7-18.51-13.76-36.17-19.99-54.49l.02,6.7.14,61.86c0,2.17-3.35,2.16-3.36,0l-.04-17.63-.14-61.86c0-1.92,2.78-2.25,3.3-.45,1.82,6.28,3.93,12.47,6.19,18.61,6.48,17.62,14.14,34.92,19.05,53.05l.23-6.25,2.48-66.92c.08-2.16,3.44-2.16,3.36,0l-.7,18.96-2.48,66.91c-.07,1.82-2.94,2.37-3.3.45-1.2-6.41-2.83-12.72-4.75-18.95Z' />
          <g>
            <path
              className='cls-5'
              d='m143.12,136.1c-1.13,4.7-2.28,9.89-3.45,15.61-6.66,32.61-34.67,50.42-48.93,47.36'
            />
            <path
              className='cls-5'
              d='m184.71,81.66c-19.71,7.8-29.43,11.1-38.77,43.67'
            />
            <path
              className='cls-5'
              d='m172.29,133.33c-5.83,7.23-8.65,17.79-5.8,33.07,4.27,22.89,14.19,39.16-11.09,62.2'
            />
            <path
              className='cls-5'
              d='m223.53,118.66c-11.49-.83-29.84-.5-42.85,7.42'
            />
            <path
              className='cls-5'
              d='m189.19,150.24c-7.23,5.83-17.79,8.65-33.07,5.8-22.89-4.27-39.16-14.19-62.2,11.09'
            />
            <path
              className='cls-5'
              d='m203.86,98.99c.83,11.49.5,29.84-7.42,42.85'
            />
            <path
              className='cls-5'
              d='m190.07,87.44c-2.12,10.89-3.59,27.54-38.89,23.83-35.3-3.71-53.03,17.82-55.98,25.1'
            />
            <path
              className='cls-5'
              d='m234.84,132.21c-10.89,2.12-27.54,3.59-23.83,38.89,3.71,35.3-17.82,53.03-25.1,55.98'
            />
            <circle className='cls-5' cx='85.18' cy='198.96' r='5.57' />
            <path
              className='cls-5'
              d='m185.98,178.95c-4.71,1.13-9.89,2.28-15.62,3.45-32.61,6.66-50.42,34.67-47.36,48.93'
            />
            <path
              className='cls-5'
              d='m240.42,137.36c-7.8,19.71-11.1,29.43-43.66,38.77'
            />
            <circle className='cls-5' cx='123.12' cy='236.89' r='5.57' />
            <circle className='cls-5' cx='176.18' cy='129.35' r='5.57' />
            <circle className='cls-5' cx='193.18' cy='146.36' r='5.57' />
            <circle className='cls-5' cx='151.47' cy='232.55' r='5.57' />
            <circle className='cls-5' cx='90.23' cy='171.31' r='5.57' />
            <circle className='cls-5' cx='92.62' cy='141.66' r='5.57' />
            <circle className='cls-5' cx='180.83' cy='229.87' r='5.57' />
            <circle className='cls-5' cx='144.49' cy='130.7' r='5.57' />
            <circle className='cls-5' cx='191.38' cy='177.59' r='5.57' />
            <g>
              <path
                className='cls-5'
                d='m185.09,31.95c-12.9-3.75-6.26-17.73,2.02-18.36,2.52-.19,7.28,1.7,8.14,9.41'
              />
              <circle className='cls-5' cx='179.1' cy='49.54' r='8.19' />
              <path
                className='cls-5'
                d='m210.55,54.57c5.18,2.26,9.19,6.69,10.5,10.74,1.77,5.49.28,8.64-2.99,15.87-3.81,8.41,4.69,16.54-.06,23.31'
              />
              <path
                className='cls-5'
                d='m220.27,1.5c1.26.34,7.91,2.62,2.81,14.83-5.1,12.21,4.28,17.71,7.97,21.13,3.69,3.42,11.1,9.65,11.86,17.76.87,9.32,4.86,14.4,8.16,16.19'
              />
              <path
                className='cls-5'
                d='m235.85,77.41s-1.47-12.68-5.87-21.45c-4.4-8.77-16.13-14.99-18.27-19.2-3.16-6.21-3.87-17.44-1.12-22.95,2.64-5.27-3.56-10.65-7.91-8.08-4.19,2.48-5.9,6.43-7.11,11.58-2.73,11.62,9.32,19.83-8.54,29.4'
              />
              <path
                className='cls-5'
                d='m271.3,25.98c-3.16-11.65-8.25-15.78-16.12-19.32-5.6-2.52-14.29-1.72-18.46,3.02-3.15,3.57-5.05,11.92,1.47,17.61,6.52,5.69,17.36,15.61,20.01,25.75'
              />
              <path
                className='cls-5'
                d='m211.71,36.76s-5.74,3.72-9.62,10.65c-3.41,6.1-3.85,10.14.17,15.45'
              />
              <circle className='cls-5' cx='236.84' cy='85.54' r='8.19' />
              <circle className='cls-5' cx='265.37' cy='57.01' r='8.19' />
              <circle className='cls-5' cx='201.06' cy='71.5' r='8.19' />
              <path
                className='cls-5'
                d='m273.15,9.85s8.23,2.49,11.79,11.11c.94,2.28,1.6,7.23-1.17,11.34-2.97,4.4-8.69,10.19-14.69,7.57-8.7-3.81-6.11-10.18-12.93-16.87-3.87-3.8-9.42-5.81-9.42-5.81'
              />
              <path
                className='cls-5'
                d='m290.54,137.4c3.75,12.9,17.73,6.26,18.36-2.02.19-2.52-1.7-7.28-9.41-8.14'
              />
              <circle className='cls-5' cx='272.95' cy='143.38' r='8.19' />
              <path
                className='cls-5'
                d='m267.91,111.94c-2.26-5.18-6.69-9.19-10.74-10.5-5.49-1.77-8.64-.28-15.87,2.99-8.41,3.81-16.54-4.69-23.31.06'
              />
              <path
                className='cls-5'
                d='m320.99,102.22c-.34-1.26-2.62-7.91-14.83-2.81-12.21,5.1-17.71-4.28-21.13-7.97-3.42-3.69-9.65-11.1-17.76-11.86-9.32-.87-14.4-4.86-16.19-8.16'
              />
              <path
                className='cls-5'
                d='m245.08,86.63s12.68,1.47,21.45,5.87c8.77,4.4,14.99,16.13,19.2,18.27,6.21,3.16,17.44,3.87,22.95,1.12,5.27-2.64,10.65,3.56,8.08,7.91-2.48,4.19-6.43,5.9-11.58,7.11-11.62,2.73-19.83-9.32-29.4,8.54'
              />
              <path
                className='cls-5'
                d='m296.5,51.19c11.65,3.16,15.78,8.25,19.32,16.12,2.52,5.6,1.72,14.29-3.02,18.46-3.57,3.15-11.92,5.05-17.61-1.47-5.69-6.52-15.61-17.36-25.75-20.01'
              />
              <path
                className='cls-5'
                d='m285.73,110.78s-3.72,5.74-10.65,9.62c-6.1,3.41-10.14,3.85-15.45-.17'
              />
              <circle className='cls-5' cx='250.99' cy='121.43' r='8.19' />
              <path
                className='cls-5'
                d='m312.64,49.34s-2.49-8.23-11.11-11.79c-2.28-.94-7.23-1.6-11.34,1.17-4.4,2.97-10.19,8.69-7.57,14.69,3.81,8.7,10.18,6.11,16.87,12.93,3.8,3.87,5.81,9.42,5.81,9.42'
              />
            </g>
          </g>
          <g>
            <path d='m3.81,407.3c-1.29,0-2.52-.52-3.66-1.7l.41-1.85c1.15,1.33,2.11,1.81,3.26,1.81,2.41,0,3.33-2.33,3.33-3.92s-.26-3.37-3.18-6.11c-2.41-2.33-3.96-4.29-3.96-7.4,0-3.29,1.78-5.74,4.4-5.74,1.96,0,3.18,1.15,4.03,2.22l-.55,1.89c-1.44-1.67-2.37-2.18-3.4-2.18-1.74,0-2.7,1.7-2.7,3.51s.67,3.44,3.15,5.81c2.78,2.63,4,4.92,4,7.73,0,3.55-2.18,5.92-5.11,5.92Z' />
            <path d='m19.28,406.82h-1.74v-22.53h-3.66v-1.55h9.07v1.55h-3.66v22.53Z' />
            <path d='m33.49,407.19c-2.81,0-4.66-1.78-4.66-5.48v-18.98h1.74v18.95c0,2.63,1.07,3.89,2.96,3.89s3-1.26,3-3.89v-18.95h1.74v18.98c0,3.7-1.92,5.48-4.77,5.48Z' />
            <path d='m45.4,406.82v-24.09h3.48c4.62,0,5.74,4.18,5.74,11.88,0,8.47-1.7,12.21-6.03,12.21h-3.18Zm1.74-22.5v20.91h1.63c2.92,0,4.07-3.15,4.07-10.51,0-6.92-.92-10.4-4-10.4h-1.7Z' />
            <path d='m61.49,382.73h1.67v24.09h-1.67v-24.09Z' />
            <path d='m75.07,407.19c-3.7,0-4.85-3.92-4.85-12.4s1.18-12.43,4.85-12.43,4.85,3.92,4.85,12.43-1.15,12.4-4.85,12.4Zm-.04-23.31c-2.37,0-3.15,3.7-3.15,10.92s.85,10.88,3.22,10.88,3.11-3.74,3.11-10.88-.78-10.92-3.18-10.92Z' />
            <path d='m97.49,387.72v19.09h-1.55v-24.09h1.55l6.33,19.13-.04-19.13h1.59v24.09h-1.55l-6.33-19.09Z' />
            <path d='m117.18,407.19c-2.81,0-4.66-1.78-4.66-5.48v-18.98h1.74v18.95c0,2.63,1.07,3.89,2.96,3.89s3-1.26,3-3.89v-18.95h1.74v18.98c0,3.7-1.92,5.48-4.77,5.48Z' />
            <path d='m139.19,386.58l-4.25,11.99-4.26-12.06v20.31h-1.52v-24.09h1.89l3.89,11.4,3.96-11.4h1.81v24.09h-1.52v-20.24Z' />
            <path d='m147.81,382.73h7.66v1.63h-5.92v8.77h4.18v1.63h-4.18v10.43h6.29v1.63h-8.03v-24.09Zm6.7-5h-2.18l-2,4.33h1.04l3.15-4.33Z' />
            <path d='m166.35,395.2c-.48.04-.93.07-1.29.07h-1.41v11.54h-1.74v-24.09h3.51c3,0,5.29,1.89,5.29,6.07,0,2.7-.59,4.51-2.66,5.7l3.11,12.32h-2.04l-2.78-11.62Zm-2.7-10.84v9.36h1.7c2.52,0,3.59-1.81,3.59-4.74s-1.11-4.63-3.59-4.63h-1.7Z' />
            <path d='m177.52,382.73h1.67v24.09h-1.67v-24.09Z' />
            <path d='m194.32,405.45c.33.59.56.89,1,1.59l-1.26.85c-.26-.41-.41-.67-.81-1.29-1,.48-1.52.59-2.15.59-3.7,0-4.85-3.96-4.85-12.43s1.26-12.4,4.85-12.4,4.85,3.92,4.85,12.4c0,4.92-.33,8.33-1.63,10.69Zm-3.26-21.57c-2.37,0-3.15,3.74-3.15,10.88s.85,10.88,3.22,10.88c.26,0,.48,0,1.26-.41-.59-1-.96-1.55-1.52-2.41l1.22-.89,1.22,1.92c.78-3.22.93-5.74.93-9.1,0-7.14-.78-10.88-3.18-10.88Z' />
            <path d='m207.31,407.19c-2.81,0-4.66-1.78-4.66-5.48v-18.98h1.74v18.95c0,2.63,1.07,3.89,2.96,3.89s3-1.26,3-3.89v-18.95h1.74v18.98c0,3.7-1.92,5.48-4.77,5.48Z' />
            <path d='m219.22,382.73h7.66v1.63h-5.92v8.77h4.18v1.63h-4.18v10.43h6.29v1.63h-8.03v-24.09Z' />
            <path d='m247.04,395.2c-.48.04-.93.07-1.29.07h-1.41v11.54h-1.74v-24.09h3.51c3,0,5.29,1.89,5.29,6.07,0,2.7-.59,4.51-2.66,5.7l3.11,12.32h-2.04l-2.78-11.62Zm-2.7-10.84v9.36h1.7c2.52,0,3.59-1.81,3.59-4.74s-1.11-4.63-3.59-4.63h-1.7Z' />
            <path d='m257.81,382.73h7.66v1.63h-5.92v8.77h4.18v1.63h-4.18v10.43h6.29v1.63h-8.03v-24.09Z' />
            <path d='m275.24,407.3c-1.29,0-2.52-.52-3.66-1.7l.41-1.85c1.15,1.33,2.11,1.81,3.26,1.81,2.41,0,3.33-2.33,3.33-3.92s-.26-3.37-3.18-6.11c-2.41-2.33-3.96-4.29-3.96-7.4,0-3.29,1.78-5.74,4.4-5.74,1.96,0,3.18,1.15,4.03,2.22l-.55,1.89c-1.44-1.67-2.37-2.18-3.4-2.18-1.74,0-2.7,1.7-2.7,3.51s.67,3.44,3.15,5.81c2.78,2.63,4,4.92,4,7.73,0,3.55-2.18,5.92-5.11,5.92Z' />
            <path d='m288.26,395.79v11.03h-1.74v-24.09h3.51c3.11,0,5.33,1.89,5.33,6.33,0,4.74-2.55,6.73-5.51,6.73h-1.59Zm0-11.43v9.77h1.7c2.37,0,3.63-1.85,3.63-4.96s-1.26-4.81-3.63-4.81h-1.7Z' />
            <path d='m305.8,407.19c-3.7,0-4.85-3.92-4.85-12.4s1.18-12.43,4.85-12.43,4.85,3.92,4.85,12.43-1.15,12.4-4.85,12.4Zm-.04-23.31c-2.37,0-3.15,3.7-3.15,10.92s.85,10.88,3.22,10.88,3.11-3.74,3.11-10.88-.78-10.92-3.18-10.92Z' />
            <path d='m318.93,387.72v19.09h-1.55v-24.09h1.55l6.33,19.13-.04-19.13h1.59v24.09h-1.55l-6.33-19.09Z' />
            <path d='m337.32,407.3c-1.29,0-2.52-.52-3.66-1.7l.41-1.85c1.15,1.33,2.11,1.81,3.26,1.81,2.41,0,3.33-2.33,3.33-3.92s-.26-3.37-3.18-6.11c-2.41-2.33-3.96-4.29-3.96-7.4,0-3.29,1.78-5.74,4.4-5.74,1.96,0,3.18,1.15,4.03,2.22l-.55,1.89c-1.44-1.67-2.37-2.18-3.4-2.18-1.74,0-2.7,1.7-2.7,3.51s.67,3.44,3.15,5.81c2.78,2.63,4,4.92,4,7.73,0,3.55-2.18,5.92-5.11,5.92Z' />
            <path d='m355.45,400.31h-5.37l-1.26,6.51h-1.67l4.66-24.09h1.92l4.66,24.09h-1.74l-1.22-6.51Zm-5.11-1.44h4.81l-2.4-12.62-2.41,12.62Z' />
            <path d='m364.18,406.82v-24.09h3.29c3.85,0,5.29,2.18,5.29,5.88,0,2.66-.81,4.33-2.63,5.25,2.04,1,3.07,3,3.07,5.96,0,5-2.07,6.99-5.59,6.99h-3.44Zm1.74-22.46v8.73h1.44c2.74,0,3.63-1.89,3.63-4.4,0-2.89-1.18-4.33-3.59-4.33h-1.48Zm0,10.36v10.47h1.81c2.78,0,3.7-1.85,3.7-5.25,0-3.22-1.18-5.22-4.14-5.22h-1.37Z' />
            <path d='m379.58,382.73h1.78v22.46h5.59v1.63h-7.36v-24.09Z' />
            <path d='m392.75,382.73h7.66v1.63h-5.92v8.77h4.18v1.63h-4.18v10.43h6.29v1.63h-8.03v-24.09Z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
