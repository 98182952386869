import React from 'react'

const WaveSeparator = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 401.9 11'
      className='mx-auto object-scale-down stroke-[2px] stroke-coral fill-none w-[300px]'
    >
      <g>
        <path d='m.39,1.08c8.04,3.36,21.8,7.92,39.39,8.04,18.36.13,32.68-4.64,40.84-8.04h0c8.04,3.36,21.8,7.92,39.39,8.04,18.36.13,32.68-4.64,40.84-8.04h0c8.04,3.36,21.8,7.92,39.39,8.04,18.36.13,32.68-4.64,40.84-8.04h0c8.04,3.36,21.8,7.92,39.39,8.04,18.36.13,32.68-4.64,40.84-8.04h0c8.04,3.36,21.8,7.92,39.39,8.04,18.36.13,32.68-4.64,40.84-8.04' />
      </g>
    </svg>
  )
}

export default WaveSeparator
