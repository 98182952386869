import axios from 'axios'
import env from 'react-dotenv'

const backofficePath = `${env.PROTOCOL}://${env.API_BASE_URL}`

const customFetch = axios.create({
  baseURL: backofficePath,
  withCredentials: true,
})

export default customFetch
