//import logo from './logo.svg';
import './App.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Navbar from './Components/Navbar'
import Landing from './Pages/Landing'
import Mentions from './Pages/Mentions'
import Politique from './Pages/Politique'
import BetaLagoon from './Pages/BetaLagoon'
import NavbarAlt from './Components/NavbarAlt'
import BetaLagoonConfirm from './Pages/BetaLagoonConfirm'

function App() {
  return (
    <main
      id='main'
      className='text-[18px] font-raleway font-normal text-grey overflow-hidden'
    >
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navbar />}>
            <Route index element={<Landing />} />
          </Route>
          <Route
            path='/mentions'
            element={
              <NavbarAlt>
                <Mentions />
              </NavbarAlt>
            }
          />
          <Route
            path='/politique'
            element={
              <NavbarAlt>
                <Politique />
              </NavbarAlt>
            }
          />
          <Route
            path='/beta-lagoon'
            element={
              <NavbarAlt>
                <BetaLagoon />
              </NavbarAlt>
            }
          ></Route>
          <Route
            path='/beta-lagoon/confirm'
            element={
              <NavbarAlt>
                <BetaLagoonConfirm />
              </NavbarAlt>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position='bottom-center' />
    </main>
  )
}

export default App
