import React from 'react'
import { motion } from 'framer-motion'
import WaveSeparator from './icons/WaveSeparator'

const isMobile = window.innerWidth < 640

const animTop = !isMobile
  ? {
      offscreen: {
        y: -300,
      },
      onscreen: {
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 0.5,
        },
      },
    }
  : {}

// const animRight = !isMobile
//   ? {
//       offscreen: {
//         opacity: 0,
//         x: 300,
//       },
//       onscreen: {
//         opacity: 1,
//         x: 0,
//         transition: {
//           type: "spring",
//           bounce: 0.2,
//           duration: 0.2,
//           mass: 0.4,
//           damping: 8,

//           staggerChildren: 0.1,
//           delay: 0.2,
//         },
//       },
//     }
//   : {};

const animScale = !isMobile
  ? {
      offscreen: {
        scale: 0,
      },
      onscreen: {
        scale: 1,
        transition: {
          type: 'spring',
          bounce: 0.2,
          duration: 2,
        },
      },
    }
  : {}

const Equipe = () => {
  return (
    <div>
      <div className='h-8 w-full bg-gradient-to-b from-[#679298] opacity-70 -z-40 relative'></div>
      <div className='pt-20 max-w-[1360px] mx-4 sm:mx-10 md:mx-20 sc:mx-auto overflow-hidden mb-16'>
        <motion.div
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.4 }}
          className='max-w-[1200px]'
        >
          <motion.p
            variants={animTop}
            className='text-[35px] sm:text-[45px] font-jost font-black text-coral uppercase leading-8 sm:leading-10 mx-auto'
          >
            L'équipe
            <br />
            Rose Lagon
          </motion.p>
        </motion.div>
        <motion.div className='container sm:overflow-hidden snap-mandatory snap-x flex flex-row overflow-x-scroll  sm:grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-10'>
          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            variants={animScale}
            className='mb-10 min-w-[300px]  snap-center'
          >
            <div className='overflow-hidden'>
              {/* <div className="bg-equipeSteph  w-[500px]  h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-auto bg-right"></div> */}
              <div className='bg-spriteEquipe  w-[500px]  h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-100px_-400px] sm:bg-[-100px_-330px] md:bg-[-70px_-330px] lg:bg-[-10px_-330px]  xl:bg-[-40px_-330px]'></div>
            </div>
            <p className=' font-black text-center mt-5 uppercase'>Stéph</p>
            <p className=' px-0 sm:px-6 text-center italic text-[#21858f]'>
              Direction des opérations
            </p>
          </motion.div>
          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            variants={animScale}
            className='mb-10 min-w-[300px] snap-center'
          >
            <div className='overflow-hidden'>
              <div className='bg-spriteEquipe  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-110px_-730px] sm:bg-[-100px_-650px] md:bg-[-70px_-650px] lg:bg-[-10px_-650px] xl:bg-[-40px_-650px]'></div>
            </div>
            <p className=' font-black text-center mt-5 uppercase'>pédro</p>
            <p className=' px-0 sm:px-6 text-center italic text-[#21858f]'>
              Direction développement
            </p>
          </motion.div>
          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            variants={animScale}
            className='mb-10 min-w-[300px] snap-center'
          >
            <div className='overflow-hidden'>
              <div className='bg-spriteEquipe  w-[500px] h-40 sm:h-[300px] hover:scale-110  transition-all duration-1000 bg-[-640px_-720px] sm:bg-[-630px_-650px] md:bg-[-600px_-650px] lg:bg-[-530px_-650px] xl:bg-[-560px_-650px]'></div>
            </div>
            <p className=' font-black text-center mt-5 uppercase'>Niko</p>
            <p className=' px-0 sm:px-6 text-center italic text-[#21858f]'>
              Direction de création
            </p>
          </motion.div>
          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            variants={animScale}
            className='mb-10 xl:mb-20 min-w-[300px] snap-center'
          >
            <div className='overflow-hidden'>
              <div className='bg-spriteEquipe  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-620px_-80px] sm:bg-[-620px_-10px] md:bg-[-590px_-10px] lg:bg-[-530px_-10px] xl:bg-[-560px_-10px]'></div>
            </div>
            <p className=' font-black text-center mt-5 uppercase'>Fran²</p>
            <p className=' px-0 sm:px-6 text-center italic text-[#21858f]'>
              Responsable studio
            </p>
          </motion.div>
          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            variants={animScale}
            className='mb-10 xl:mb-20 min-w-[300px] snap-center'
          >
            <div className='overflow-hidden'>
              <div className='bg-spriteEquipe  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-120px_-90px] sm:bg-[-110px_-10px] md:bg-[-80px_-10px] lg:bg-[-10px_-10px] xl:bg-[-45px_-10px]'></div>
            </div>
            <p className=' font-black text-center mt-5 uppercase'>Pych</p>
            <p className=' px-0 sm:px-6 text-center italic text-[#21858f]'>
              Graphiste hyper(inter)actif !
            </p>
          </motion.div>
          <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            variants={animScale}
            className='mb-10 xl:mb-20 min-w-[300px] snap-center'
          >
            <div className='overflow-hidden'>
              <div className='bg-spriteEquipe  w-[500px] h-40 sm:h-[300px] hover:scale-110 transition-all duration-1000 bg-[-630px_-410px] sm:bg-[-630px_-330px] md:bg-[-590px_-330px] lg:bg-[-530px_-330px] xl:bg-[-560px_-330px]'></div>
            </div>
            <p className=' font-black text-center mt-5 uppercase'>Judy</p>
            <p className=' px-0 sm:px-6 text-center italic text-[#21858f]'>
              Ingénieure pédagogique
            </p>
          </motion.div>
        </motion.div>
        <div className='sm:mt-10 pt-10 mb-10'>
          <WaveSeparator />
        </div>
      </div>
    </div>
  )
}

export default Equipe
